import * as React from "react";
import * as ReactRouterDom from "react-router-dom";

import * as CustomHooks from "CustomHooks";
import * as DateUtils from "DateUtils";
import * as JsonUtils from "JsonUtils";
import * as MathUtils from "Utils/MathUtils";
import * as QueryUtils from "QueryUtils";
import * as RequestGraphConstants from "RequestGraphConstants";
import * as StrictUtils from "Utils/StrictUtils";
import { DateDisplayTimeZone } from "DateDisplayTimeZone";
import { GraphQuery } from "GraphQuery";
import { GraphQueryColumnName } from "GraphQueryColumnName";
import { DevtoolsQuery } from "DevtoolsQuery";
import { QueryResult } from "QueryResult";
import { RequestGraph } from "RequestGraph";
import { QueryManager } from "QueryManager";
import { Toggle } from "Toggle";
import { UrlFilterState } from "UrlFilterState";
import { ChromeDevToolsPanel } from "ChromeDevToolsPanel";

const filterSearchParamName: string = "filter";

export function RequestsPage(): React.ReactNode {
  const { currentNamespace } = CustomHooks.useNamespaceManager();
  const { namespaceId } = currentNamespace;

  const [searchParams, setSearchParams] = ReactRouterDom.useSearchParams();
  const initialFilterSearchParam: string | null = searchParams.get(filterSearchParamName);

  let urlFilterState: UrlFilterState | undefined = undefined;
  try {
    if (initialFilterSearchParam != null) {
      urlFilterState = JsonUtils.parse(atob(initialFilterSearchParam));
    }
  } catch {
    // Do nothing, the filter is likely not valid JSON
  }

  const [displayTimeZone, setDisplayTimeZone] = React.useState<DateDisplayTimeZone>(DateDisplayTimeZone.UTC);

  const initialGraphGlobalRightTimestamp: number =
    urlFilterState?.globalTimestampFilter?.upperBoundTimestamp ??
    MathUtils.roundUpToGranularity(Date.now(), RequestGraphConstants.DEFAULT_GRANULARITY, DateUtils.getTimeZoneOffset(displayTimeZone));
  const initialGraphGlobalLeftTimestamp: number =
    urlFilterState?.globalTimestampFilter?.lowerBoundTimestamp ??
    initialGraphGlobalRightTimestamp - RequestGraphConstants.NUMBER_OF_BARS_ON_SCREEN * RequestGraphConstants.DEFAULT_GRANULARITY;
  const initialGraphFocusedRightTimestamp: number = urlFilterState?.focusedTimestampFilter?.upperBoundTimestamp ?? initialGraphGlobalRightTimestamp;
  const initialGraphFocusedLeftTimestamp: number = urlFilterState?.focusedTimestampFilter?.lowerBoundTimestamp ?? initialGraphGlobalLeftTimestamp;

  const [evaluatingDevtoolsQuery, setEvaluatingDevtoolsQuery] = React.useState<DevtoolsQuery | undefined>({
    clauses: urlFilterState?.clauses ?? [],
    timestampFilter: {
      lowerBoundTimestamp: initialGraphFocusedLeftTimestamp,
      upperBoundTimestamp: initialGraphFocusedRightTimestamp,
    },
  });
  const [appliedDevtoolsQuery, setAppliedDevtoolsQuery] = React.useState<DevtoolsQuery | undefined>(undefined);
  const [devtoolsQueryResult, setDevtoolsQueryResult] = React.useState<QueryResult | undefined>(undefined);
  const evaluatingDevtoolsQueryId: React.MutableRefObject<string> = React.useRef(crypto.randomUUID());

  const [graphCountByTimestamp, setGraphCountByTimestamp] = React.useState<Map<number, number>>(new Map());
  const [graphGlobalLeftTimestamp, setGraphGlobalLeftTimestamp] = React.useState<number>(initialGraphGlobalLeftTimestamp);
  const [graphGlobalRightTimestamp, setGraphGlobalRightTimestamp] = React.useState<number>(initialGraphGlobalRightTimestamp);
  const [graphFocusedLeftTimestamp, setGraphFocusedLeftTimestamp] = React.useState<number>(initialGraphFocusedLeftTimestamp);
  const [graphFocusedRightTimestamp, setGraphFocusedRightTimestamp] = React.useState<number>(initialGraphFocusedRightTimestamp);
  const [evaluatingGraphQuery, setEvaluatingGraphQuery] = React.useState<GraphQuery | undefined>({
    clauses: urlFilterState?.clauses ?? [],
    displayTimeZone,
    timestampFilter: {
      lowerBoundTimestamp: initialGraphGlobalLeftTimestamp,
      upperBoundTimestamp: initialGraphGlobalRightTimestamp,
    },
  });
  const evaluatingGraphQueryId: React.MutableRefObject<string> = React.useRef(crypto.randomUUID());

  const queryManager: QueryManager = QueryManager.getInstance();
  queryManager.setOnQueryEvaluated(onQueryEvaluated);

  React.useEffect(
    function evaluateDevtoolsQuery(): void {
      if (evaluatingDevtoolsQuery == null) {
        return;
      }
      const queryId: string = crypto.randomUUID();
      evaluatingDevtoolsQueryId.current = queryId;

      queryManager.queueEvaluation(namespaceId, QueryUtils.toDevtoolsSqlQuery(namespaceId, evaluatingDevtoolsQuery), queryId);
    },
    [evaluatingDevtoolsQuery, namespaceId, queryManager],
  );

  React.useEffect(
    function evaluateGraphQuery(): void {
      if (evaluatingGraphQuery == null) {
        return;
      }
      const queryId: string = crypto.randomUUID();
      evaluatingGraphQueryId.current = queryId;

      queryManager.queueEvaluation(namespaceId, QueryUtils.toGraphSqlQuery(namespaceId, evaluatingGraphQuery), queryId);
    },
    [evaluatingGraphQuery, namespaceId, queryManager],
  );

  return (
    <div className="flex w-full h-screen justify-center">
      <div className="flex flex-col items-center w-full h-full px-4 pt-6 space-y-8">
        <div className="flex flex-row w-full">
          <div className="w-full flex flex-col max-w-[90%] space-y-8">
            <RequestGraph
              displayTimeZone={displayTimeZone}
              focusedLeftTimestamp={graphFocusedLeftTimestamp}
              focusedRightTimestamp={graphFocusedRightTimestamp}
              globalLeftTimestamp={graphGlobalLeftTimestamp}
              globalRightTimestamp={graphGlobalRightTimestamp}
              countByTimestamp={graphCountByTimestamp}
              isLoading={evaluatingGraphQuery !== undefined}
              keepGlobalRangeFixed={false}
              onFocusedTimeRangeChanged={onGraphFocusedTimeRangeChanged}
              onGlobalTimeRangeChanged={onGraphGlobalRangeChanged}
            />
          </div>
          <div className="flex flex-col space-y-2 text-zinc-500 text-xs">
            <div className="flex flex-row space-x-2 ">
              <span>UTC</span>
              <Toggle
                className="self-start"
                checked={displayTimeZone === DateDisplayTimeZone.CurrentTimeZone}
                onChange={(value) => setDisplayTimeZone(value ? DateDisplayTimeZone.CurrentTimeZone : DateDisplayTimeZone.UTC)}
              />
              <span>Local</span>
            </div>
          </div>
        </div>
        <ChromeDevToolsPanel isLoading={evaluatingDevtoolsQuery != null} queryResult={devtoolsQueryResult} />
      </div>
    </div>
  );

  function onGraphGlobalRangeChanged(startTimestamp: number, endTimestamp: number): void {
    setGraphGlobalLeftTimestamp(startTimestamp);
    setGraphGlobalRightTimestamp(endTimestamp);
    setEvaluatingGraphQuery({
      clauses: (evaluatingDevtoolsQuery ?? appliedDevtoolsQuery)?.clauses ?? [],
      displayTimeZone,
      timestampFilter: {
        lowerBoundTimestamp: startTimestamp,
        upperBoundTimestamp: endTimestamp,
      },
    });
  }

  function onGraphFocusedTimeRangeChanged(startTimestamp: number, endTimestamp: number): void {
    setGraphFocusedLeftTimestamp(startTimestamp);
    setGraphFocusedRightTimestamp(endTimestamp);
    setEvaluatingDevtoolsQuery({
      clauses: (evaluatingDevtoolsQuery ?? appliedDevtoolsQuery)?.clauses ?? [],
      timestampFilter: {
        lowerBoundTimestamp: startTimestamp,
        upperBoundTimestamp: endTimestamp,
      },
    });
  }

  function onQueryEvaluated(queryId: string, queryResult: QueryResult): void {
    if (queryId === evaluatingDevtoolsQueryId.current) {
      setDevtoolsQueryResult(queryResult);
      setAppliedDevtoolsQuery(evaluatingDevtoolsQuery);
      setEvaluatingDevtoolsQuery(undefined);
      updateUrlFilter({ clauses: evaluatingDevtoolsQuery?.clauses, focusedTimestampFilter: evaluatingDevtoolsQuery?.timestampFilter });
    } else if (queryId === evaluatingGraphQueryId.current) {
      const countsByBinnedTimestamp: Map<number, number> = new Map(
        queryResult.data.map((row) => {
          let dateString: string = StrictUtils.ensureDefined(row[GraphQueryColumnName.BinnedTimestamp]);
          // Ensure that we interpret this as a UTC timestamp.
          dateString = dateString.endsWith("Z") ? dateString : dateString + "Z";
          const count: number = parseInt(StrictUtils.ensureDefined(row[GraphQueryColumnName.Count]));
          return [new Date(dateString).valueOf(), count];
        }),
      );

      setGraphCountByTimestamp(countsByBinnedTimestamp);
      setEvaluatingGraphQuery(undefined);
      updateUrlFilter({ clauses: evaluatingGraphQuery?.clauses, globalTimestampFilter: evaluatingGraphQuery?.timestampFilter });
    }
    return;
  }

  function updateUrlFilter(urlFilterState?: Partial<UrlFilterState>): void {
    const updatedUrlFilterState: UrlFilterState = {
      clauses: appliedDevtoolsQuery?.clauses,
      focusedTimestampFilter: { lowerBoundTimestamp: graphFocusedLeftTimestamp, upperBoundTimestamp: graphFocusedRightTimestamp },
      globalTimestampFilter: { lowerBoundTimestamp: graphGlobalLeftTimestamp, upperBoundTimestamp: graphGlobalRightTimestamp },
      ...urlFilterState,
    };

    setSearchParams(
      (params) => {
        params.set(filterSearchParamName, btoa(JsonUtils.stringify(updatedUrlFilterState)));
        return params;
      },
      { replace: true },
    );
  }
}
