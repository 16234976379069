import * as React from "react";

export const Glitch = (props: { icon: React.ReactNode }) => (
  <React.Fragment>
    <div className="transition-all ease-in-out duration-[200ms] grayscale group-hover:grayscale-0 group-hover:blur-[0px] opacity-[0.00] group-hover:opacity-[1.00] flex justify-center items-center">
      <div className="w-[24px] h-[24px] absolute">
        <div className="mx-auto absolute top-0 left-0 w-full h-full opacity-[0.40] text-zinc-300">{props.icon}</div>
        <div className="mx-auto absolute top-0 left-0 w-full h-full opacity-[0.40] animate-[glitch-1_1.7s_step-start_infinite] text-rose-500">{props.icon}</div>
        <div className="mx-auto absolute top-0 left-0 w-full h-full opacity-[0.40] animate-[glitch-2_1.9s_step-start_infinite] text-amber-500">{props.icon}</div>
        <div className="mx-auto absolute top-0 left-0 w-full h-full opacity-[0.40] animate-[glitch-2_2.9s_step-start_infinite] text-emerald-500">{props.icon}</div>
        <div className="mx-auto absolute top-0 left-0 w-full h-full opacity-[0.40] animate-[glitch-1_3.1s_step-start_infinite] text-cyan-500">{props.icon}</div>
        <div className="mx-auto absolute top-0 left-0 w-full h-full opacity-[0.40] animate-[glitch-2_3.7s_step-start_infinite] text-sky-500">{props.icon}</div>
        <div className="mx-auto absolute top-0 left-0 w-full h-full opacity-[0.40] animate-[glitch-1_4.1s_step-start_infinite] text-violet-500">{props.icon}</div>
      </div>
    </div>
  </React.Fragment>
);
