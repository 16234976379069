import * as React from "react";

export function LogoIcon(props: { className?: string }): React.ReactElement {
  return (
    <svg className={props.className} viewBox="0 0 720 720" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path
          d="M401.208,40.025L372.878,40.025C253.974,38.308 172.416,124.159 172.416,227.609C172.396,262.779 182.275,296.554 200.01,325.54C213.554,347.674 231.671,367.033 253.473,382.062L298.656,277.455C288.073,264.046 281.755,247.119 281.755,228.728C281.755,187.598 313.357,153.79 353.585,150.283L401.208,40.025Z"
          fill="currentColor"
        />
        <path
          d="M172.416,569.725L253.473,382.062C259.385,386.137 265.567,389.894 272.003,393.301C294.742,405.341 319.65,412.188 346.693,419.486L368.585,424.637C384.129,428.147 399.673,434 412.053,444.248L354.284,569.522C351.934,569.656 349.546,569.725 347.122,569.725L172.416,569.725Z"
          fill="currentColor"
        />
        <path
          d="M457.912,344.802C512.169,375.602 547.584,424.6 547.584,492.845C547.584,596.682 463.879,680 347.122,680L303.338,680L354.284,569.522C395.635,567.159 425.302,544.361 433.832,513.492C437.708,500.396 437.256,486.391 432.544,473.572C428.08,461.074 420.869,451.546 412.053,444.248L457.912,344.802Z"
          fill="currentColor"
        />
        <path
          d="M334.039,302.91C319.996,296.422 308.087,287.266 299.287,275.993L353.438,150.624C355.602,150.438 357.791,150.344 360,150.344L547.584,150.344L457.912,344.802C429.615,328.739 396.195,317.626 360,311.314C350.772,309.451 342.058,306.615 334.039,302.91Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

export function LogoText(props: { className?: string }): React.ReactElement {
  return (
    <svg className={props.className} viewBox="0 0 1400 360" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g>
        <g>
          <path
            d="M147.732,21.381L133.687,21.381C74.744,20.53 34.314,63.088 34.314,114.371C34.304,131.805 39.201,148.549 47.993,162.917C54.707,173.89 63.688,183.486 74.496,190.937L96.894,139.081C91.648,132.434 88.516,124.043 88.516,114.926C88.516,94.536 104.182,77.777 124.123,76.039L147.732,21.381Z"
            fill="currentColor"
          />
          <path
            d="M34.314,283.965L74.496,190.937C77.426,192.957 80.491,194.819 83.681,196.508C94.954,202.477 107.301,205.871 120.707,209.488L131.559,212.042C139.265,213.782 146.971,216.683 153.107,221.764L124.47,283.865C123.305,283.931 122.121,283.965 120.92,283.965L34.314,283.965Z"
            fill="currentColor"
          />
          <path
            d="M175.841,172.466C202.737,187.734 220.293,212.024 220.293,245.854C220.293,297.329 178.799,338.631 120.92,338.631L99.215,338.631L124.47,283.865C144.968,282.694 159.675,271.392 163.904,256.09C165.825,249.597 165.601,242.655 163.265,236.3C161.053,230.105 157.478,225.382 153.107,221.764L175.841,172.466Z"
            fill="currentColor"
          />
          <path
            d="M114.434,151.699C107.473,148.483 101.569,143.944 97.207,138.356L124.05,76.208C125.123,76.115 126.208,76.069 127.304,76.069L220.293,76.069L175.841,172.466C161.814,164.503 145.246,158.994 127.304,155.865C122.729,154.941 118.409,153.536 114.434,151.699Z"
            fill="currentColor"
          />
        </g>
        <g>
          <path
            d="M401.585,234.675C430.735,234.675 447.727,220.833 447.727,199.08C447.727,181.941 437.034,172.639 414.329,168.538L403.709,166.633C393.162,164.729 388.621,162.458 388.621,157.698C388.621,152.937 393.015,149.128 401.658,149.128C408.323,149.128 413.23,151.106 416.672,156.379C420.408,162.019 424.583,164.29 431.174,164.29C438.938,164.216 444.065,159.456 444.065,152.424C444.065,150.007 443.626,148.03 442.747,145.906C437.253,132.722 422.239,125.325 401.292,125.325C376.169,125.325 357.859,138.728 357.859,159.968C357.859,176.375 369.358,186.995 390.378,190.73L400.999,192.634C412.864,194.758 416.965,196.882 416.965,201.863C416.965,207.063 411.252,210.872 401.951,210.872C394.993,210.872 388.84,208.748 385.178,203.547C381.003,197.688 377.048,195.857 371.189,195.857C363.499,195.857 357.859,200.984 357.859,208.455C357.859,210.872 358.372,213.362 359.47,215.779C364.011,226.106 377.781,234.675 401.585,234.675Z"
            style={{ fillRule: "nonzero" }}
            fill="currentColor"
          />
          <path
            d="M535.881,234.675C563.787,234.675 582.463,219.294 582.463,195.198L582.463,141.584C582.463,131.184 576.824,125.325 566.936,125.325C557.048,125.325 551.409,131.184 551.409,141.584L551.409,191.755C551.409,202.888 545.916,208.967 535.881,208.967C525.847,208.967 520.354,202.888 520.354,191.755L520.354,141.584C520.354,131.184 514.714,125.325 504.827,125.325C494.939,125.325 489.299,131.184 489.299,141.584L489.299,195.198C489.299,219.294 507.976,234.675 535.881,234.675Z"
            style={{ fillRule: "nonzero" }}
            fill="currentColor"
          />
          <path
            d="M642.639,232.844L680.798,232.844C703.43,232.844 718.079,220.613 718.079,202.083C718.079,188.093 706.14,177.327 691.858,176.96L691.858,176.375C704.089,174.836 713.318,165.681 713.318,153.523C713.318,137.043 700.647,127.156 679.041,127.156L642.639,127.156C632.752,127.156 627.112,133.015 627.112,143.416L627.112,216.584C627.112,226.985 632.752,232.844 642.639,232.844ZM658.167,169.49L658.167,148.469L670.618,148.469C678.455,148.469 683.142,152.424 683.142,158.943C683.142,165.315 678.162,169.49 670.178,169.49L658.167,169.49ZM658.167,211.531L658.167,187.288L671.643,187.288C680.945,187.288 686.511,191.682 686.511,199.226C686.511,207.209 681.018,211.531 671.423,211.531L658.167,211.531Z"
            style={{ fillRule: "nonzero" }}
            fill="currentColor"
          />
          <path
            d="M797.664,234.675C807.551,234.675 813.191,228.816 813.191,218.416L813.191,151.912L828.792,151.912C836.995,151.912 842.488,147.444 842.488,139.534C842.488,131.624 837.141,127.156 828.792,127.156L766.536,127.156C758.186,127.156 752.839,131.624 752.839,139.534C752.839,147.444 758.333,151.912 766.536,151.912L782.136,151.912L782.136,218.416C782.136,228.816 787.776,234.675 797.664,234.675Z"
            style={{ fillRule: "nonzero" }}
            fill="currentColor"
          />
          <path
            d="M897.17,234.675C907.058,234.675 912.698,228.816 912.698,218.416L912.698,197.395L925.076,197.395L938.992,222.224C944.485,231.965 947.561,234.675 955.178,234.675C964.187,234.675 970.632,228.743 970.632,220.466C970.632,216.804 970.046,214.387 967.336,209.846L956.277,191.682C966.311,185.823 971.292,174.763 971.292,163.044C971.292,140.413 956.79,127.156 928.591,127.156L897.17,127.156C887.283,127.156 881.643,133.015 881.643,143.416L881.643,218.416C881.643,228.816 887.283,234.675 897.17,234.675ZM912.698,176.887L912.698,150.081L925.588,150.081C933.938,150.081 939.578,155.72 939.578,163.557C939.578,171.541 934.231,176.887 925.515,176.887L912.698,176.887Z"
            style={{ fillRule: "nonzero" }}
            fill="currentColor"
          />
          <path
            d="M1027.44,234.675C1035.86,234.675 1040.55,230.354 1043.48,219.734L1045.31,213.069L1079.73,213.069L1081.57,220.247C1084.2,230.5 1088.96,234.675 1098.12,234.675C1107.13,234.675 1113.64,228.376 1113.64,219.587C1113.64,216.218 1113.06,213.289 1111.59,209.26L1087.72,143.928C1082.96,130.891 1075.34,125.325 1062.08,125.325C1049.7,125.325 1042.01,131.111 1037.47,143.928L1013.82,209.26C1012.42,212.996 1011.62,216.951 1011.62,219.661C1011.62,229.036 1017.55,234.675 1027.44,234.675ZM1051.17,189.924L1061.64,151.545L1062.23,151.545L1073.21,189.924L1051.17,189.924Z"
            style={{ fillRule: "nonzero" }}
            fill="currentColor"
          />
          <path
            d="M1199.53,234.675C1220.11,234.675 1237.91,224.495 1244.06,209.187C1245.16,206.477 1245.75,203.694 1245.75,201.057C1245.75,192.781 1239.96,187.361 1231.1,187.361C1224.58,187.361 1220.84,189.924 1217.69,196.589C1213.23,205.964 1207.29,210.286 1198.8,210.286C1185.91,210.286 1177.92,199.006 1177.92,179.89C1177.92,160.994 1185.98,149.714 1198.72,149.714C1206.85,149.714 1212.71,153.889 1217.4,162.898C1220.77,169.417 1224.87,172.053 1231.61,172.053C1239.74,172.053 1245.09,166.926 1245.09,159.016C1245.09,155.281 1243.99,151.399 1241.86,147.737C1233.51,133.015 1218.57,125.325 1198.36,125.325C1166.42,125.325 1146.28,145.1 1146.28,179.963C1146.28,214.827 1165.98,234.675 1199.53,234.675Z"
            style={{ fillRule: "nonzero" }}
            fill="currentColor"
          />
          <path
            d="M1305.19,232.844L1351.99,232.844C1360.34,232.844 1365.69,228.376 1365.69,220.466C1365.69,212.556 1360.19,208.088 1351.99,208.088L1320.71,208.088L1320.71,191.169L1350.75,191.169C1358.29,191.169 1362.98,187.141 1362.98,180.037C1362.98,172.932 1358.14,168.904 1350.75,168.904L1320.71,168.904L1320.71,151.912L1351.99,151.912C1360.19,151.912 1365.69,147.444 1365.69,139.534C1365.69,131.624 1360.34,127.156 1351.99,127.156L1305.19,127.156C1295.3,127.156 1289.66,133.015 1289.66,143.416L1289.66,216.584C1289.66,226.985 1295.3,232.844 1305.19,232.844Z"
            style={{ fillRule: "nonzero" }}
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
}
