import * as React from "react";

import * as Api from "ApiContracts/control/api/api";
import * as ApiUtils from "ApiUtils";
import { LogoText } from "Logo";
import { Copy, Check, Lock, LoaderCircle } from "lucide-react";

function getLinkId(): string | null {
  const parts = window.location.pathname.split("/");
  if (parts.length >= 3) {
    return parts[2];
  }
  return null;
}

export function LinkPage(): React.ReactElement {
  const [linkUrl, setLinkUrl] = React.useState<string | null>(null);
  const [pingConn, setPingConn] = React.useState<WebSocket | null>(null);
  const [isCopied, setCopied] = React.useState<boolean>(false);

  React.useEffect(() => {
    const linkId = getLinkId();
    if (!linkId) {
      console.error(`missing link_id parameter`);
      return;
    }

    if (!linkUrl) {
      (async (): Promise<void> => {
        const { signal }: AbortController = new AbortController();
        const request: Api.GetLink_Request = { linkId };
        const response: Response = await ApiUtils.post("/api/GetLink", request, { signal });
        await ApiUtils.assertStatus(response, 200);
        const { linkUrl }: Api.GetLink_Response = await response.json();
        setLinkUrl(linkUrl);
      })();
    } else if (!pingConn) {
      const ws = new WebSocket(`/api/PingLink?link_id=${linkId}`);
      ws.onopen = (): void => {
        console.debug("opened ping conn");
        const id = setInterval((): void => {
          ws.send("ping");
        }, 1000);
        ws.onclose = (): void => {
          clearInterval(id);
          setTimeout((): void => setPingConn(null), 1000);
        };
      };
      setPingConn(ws);
    }

    return (): void => {
      if (pingConn) {
        pingConn.close();
      }
    };
  }, [linkUrl, pingConn]);

  const linkId = getLinkId();

  const copyLink = async (): Promise<void> => {
    await navigator.clipboard.writeText(`https://subt.link/${linkId}`);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  if (!linkId) {
    document.location = "/";
    return <React.Fragment />;
  }

  if (!linkUrl) {
    return (
      <div className="w-screen h-screen flex justify-center items-center">
        <LoaderCircle className="animate-spin text-zinc-500 w-8 h-8" />
      </div>
    );
  }

  const commitHash = (window as any).subtrace.version.commitHash;

  return (
    <div className="w-screen h-screen flex flex-col space-y-2 p-2">
      <div className="grow-0 shrink-0 px-4 py-2 w-full flex justify-between items-center">
        <a href="/" className="w-32 px-2 -mx-2 py-1 -mx-1">
          <LogoText />
        </a>
        <button onClick={copyLink} className="flex h-full bg-zinc-900 border border-zinc-800 group rounded">
          <div className="flex h-full text-[10px] select-none items-center px-4 space-x-2 text-zinc-500 bg-zinc-800 uppercase">
            <Lock className="w-[12px] h-[12px]" />
            <span>Share</span>
          </div>
          <div className="group-hover:bg-zinc-800 flex items-center space-x-2 pr-2">
            <div className="text-[10px] cursor-pointer text-zinc-300 font-mono px-4 py-2 select-all">https://subt.link/{linkId}</div>
            {isCopied ? <Check className="w-4 h-4 text-zinc-400" /> : <Copy className="opacity-0 group-hover:opacity-[1.00] w-4 h-4 text-zinc-600" />}
          </div>
        </button>
      </div>
      <div className="grow w-full h-full">
        <iframe
          sandbox="allow-modals allow-popups allow-scripts allow-same-origin allow-downloads"
          allow="clipboard-write"
          className="rounded h-full w-full"
          src={`/iframer?commit=${commitHash}#url=${encodeURIComponent(linkUrl)}`}
        />
      </div>
    </div>
  );
}
