import * as React from "react";
import { Loader2, MailCheck } from "lucide-react";

import * as CustomHooks from "CustomHooks";
import * as Api from "ApiContracts/control/api/api";
import * as ApiUtils from "ApiUtils";
import * as ApiEndpointUtils from "ApiEndpointUtils";
import { LogoIcon } from "Logo";

async function emailLogin(email: string): Promise<boolean> {
  const request: Api.EmailLogin_Request = { email };
  const response: Response = await ApiUtils.post("/api/EmailLogin", request);
  await ApiUtils.assertStatus(response, 200);
  const { error }: Api.ListUsers_Response = await response.json();
  if (error) {
    alert(`login failed: ${error}`);
    return false;
  }
  return true;
}

function ssoLogin(path: string): void {
  const nextUrl: string | null = new URL(window.location.href).searchParams.get("next");
  const loginUrl: URL = new URL(ApiEndpointUtils.getApiEndpoint() + path);
  if (nextUrl !== null) {
    loginUrl.searchParams.append("next", nextUrl);
  }

  window.location.href = loginUrl.href;
}

export function LoginPage(): React.ReactElement {
  const currentUser = CustomHooks.useCurrentUser();

  const [isSubmitting, setSubmitting] = React.useState<boolean>(false);
  const [submittedEmail, setSubmittedEmail] = React.useState<string | null>(null);
  const inputId = React.useId();

  const submit = async (ev: React.FormEvent<HTMLFormElement>): Promise<void> => {
    ev.preventDefault();

    setSubmitting(true);
    const email = (document.getElementById(inputId)! as HTMLInputElement).value;
    const ok = await emailLogin(email);
    if (ok) {
      setSubmittedEmail(email);
    }
    setSubmitting(false);
  };

  if (!currentUser) {
    return <div />;
  } else if (currentUser.isLoggedIn) {
    document.location = "/dashboard";
    return <div />;
  }

  if (submittedEmail !== null) {
    return (
      <div className="w-dvw h-dvh flex flex-col">
        <div className="flex grow justify-center items-begin pt-8">
          <div className="flex flex-col space-y-4">
            <div className="w-full h-full flex flex-col align-middle px-4 py-4 space-y-8">
              <div className="flex justify-center items-center py-2">
                <LogoIcon className="w-8 text-zinc-300" />
              </div>

              <div className="flex items-center justify-center">
                <div className="p-[5px] rounded-2xl border border-zinc-700 outline outline-zinc-700/50 outline-offset-2">
                  <div className="p-3 rounded-xl border border-zinc-500 outline outline-zinc-600 outline-offset-2">
                    <MailCheck className="w-4 h-4 text-zinc-400" strokeWidth={1.5} />
                  </div>
                </div>
              </div>

              <div className="flex items-center space-x-4 text-xs">
                <div className="text-zinc-400">
                  <span>We emailed a magic link to</span>
                  <span> </span>
                  <span className="text-zinc-300 font-medium">{submittedEmail}</span>
                  <span>.</span>
                  <br />
                  <span>Click the link to continue.</span>
                </div>
              </div>

              <div className="text-xs">
                <span className="text-zinc-500">Need help?</span>{" "}
                <a className="text-sky-600" href="mailto:support@subtrace.dev">
                  Contact support
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="w-dvw h-dvh flex flex-col">
      <div className="flex grow justify-center items-begin pt-8">
        <div className="w-full max-w-[20rem] flex flex-col space-y-4">
          <div className="w-full h-full flex flex-col align-middle px-4 py-4 space-y-8">
            <div className="flex justify-center items-center py-2">
              <LogoIcon className="w-8 text-zinc-300" />
            </div>
            <form className="flex flex-col justify-center space-y-3" onSubmit={(ev) => submit(ev)}>
              <input
                required
                autoFocus
                type="email"
                id={inputId}
                className="min-h-[36px] text-zinc-300 placeholder:text-zinc-600 text-sm px-3 py-[6px] bg-zinc-900 outline outline-zinc-800 focus:outline-zinc-700 rounded"
                placeholder="Enter your email..."
              />
              <button disabled={isSubmitting} type="submit" className="flex items-center justify-center rounded bg-white py-[6px] text-sm disabled:opacity-[0.50]">
                <span className="w-0 h-[24px]"></span>
                {isSubmitting ? <Loader2 className="animate-spin w-4 h-4" /> : <span>Sign in</span>}
              </button>
            </form>
            <div className="text-zinc-600 text-center text-xs">OR</div>
            <div className="flex flex-col space-y-3">
              <button className="flex items-center justify-center space-x-2 rounded bg-white py-[6px] text-sm" onClick={() => ssoLogin("/api/GoogleRedirect")}>
                <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_829_635)">
                    <path
                      d="M19.8094 12.1497C19.8094 11.4942 19.7562 11.0158 19.6411 10.5198H12.1558V13.4784H16.5495C16.4609 14.2137 15.9826 15.321 14.9195 16.0651L14.9046 16.1641L17.2714 17.9976L17.4353 18.0139C18.9412 16.6232 19.8094 14.5769 19.8094 12.1497Z"
                      fill="#959AA1"
                    ></path>
                    <path
                      d="M12.1557 19.945C14.3083 19.945 16.1153 19.2363 17.4353 18.0139L14.9195 16.065C14.2463 16.5345 13.3427 16.8623 12.1557 16.8623C10.0474 16.8623 8.25806 15.4716 7.6202 13.5493L7.5267 13.5573L5.06575 15.4618L5.03357 15.5513C6.34459 18.1556 9.03754 19.945 12.1557 19.945Z"
                      fill="#70757E"
                    ></path>
                    <path
                      d="M7.62023 13.5494C7.45193 13.0533 7.35453 12.5218 7.35453 11.9726C7.35453 11.4233 7.45193 10.8918 7.61138 10.3958L7.60692 10.2901L5.11514 8.35498L5.03361 8.39376C4.49327 9.47449 4.18323 10.6881 4.18323 11.9726C4.18323 13.257 4.49327 14.4706 5.03361 15.5513L7.62023 13.5494Z"
                      fill="#959AA1"
                    ></path>
                    <path
                      d="M12.1557 7.08269C13.6527 7.08269 14.6626 7.72934 15.2384 8.26974L17.4884 6.07286C16.1065 4.7884 14.3083 4 12.1557 4C9.03754 4 6.34459 5.78937 5.03357 8.39371L7.61134 10.3957C8.25806 8.47347 10.0474 7.08269 12.1557 7.08269Z"
                      fill="#70757E"
                    ></path>
                  </g>
                  <defs>
                    <clipPath id="clip0_829_635">
                      <rect fill="white" height="16" transform="translate(4 4)" width="16"></rect>
                    </clipPath>
                  </defs>
                </svg>
                <span>Sign in with Google</span>
              </button>
              <button className="flex items-center justify-center space-x-2 rounded bg-white py-[6px] text-sm" onClick={() => ssoLogin("/api/GithubRedirect")}>
                <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M12 4C7.58267 4 4 7.67255 4 12.2022C4 15.8263 6.292 18.9007 9.47133 19.9855C9.87067 20.0614 10 19.8071 10 19.5911V18.0641C7.77467 18.5603 7.31133 17.0962 7.31133 17.0962C6.94733 16.1482 6.42267 15.896 6.42267 15.896C5.69667 15.3868 6.478 15.3977 6.478 15.3977C7.28133 15.4551 7.704 16.2432 7.704 16.2432C8.41733 17.4968 9.57533 17.1345 10.032 16.9247C10.1033 16.395 10.3107 16.0327 10.54 15.8283C8.76333 15.6198 6.89533 14.9165 6.89533 11.7744C6.89533 10.8783 7.208 10.1469 7.71933 9.57274C7.63667 9.36563 7.36267 8.53106 7.79733 7.40188C7.79733 7.40188 8.46933 7.18179 9.998 8.24261C10.636 8.06079 11.32 7.96989 12 7.96647C12.68 7.96989 13.3647 8.06079 14.004 8.24261C15.5313 7.18179 16.202 7.40188 16.202 7.40188C16.6373 8.53174 16.3633 9.36632 16.2807 9.57274C16.794 10.1469 17.104 10.8789 17.104 11.7744C17.104 14.9247 15.2327 15.6185 13.4513 15.8215C13.738 16.0758 14 16.5747 14 17.3403V19.5911C14 19.8091 14.128 20.0655 14.534 19.9848C17.7107 18.8987 20 15.8249 20 12.2022C20 7.67255 16.418 4 12 4Z"
                    fill="currentColor"
                  ></path>
                </svg>
                <span>Sign in with Github</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
