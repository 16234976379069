import * as React from "react";
import { TriangleAlertIcon } from "lucide-react";

import * as BrowserUtils from "BrowserUtils";
import * as QueryUtils from "QueryUtils";
import { Overlay } from "Overlay";
import { QueryResult } from "QueryResult";
import { Spinner } from "DesignComponents/Spinner";

export function ChromeDevToolsPanel(props: ChromeDevToolsPanel): React.ReactNode {
  const [previousQueryId, setPreviousQueryId] = React.useState<string | undefined>(props.queryResult?.queryId);
  const devToolsPanelRef: React.MutableRefObject<HTMLIFrameElement | null> = React.useRef(null);

  if (previousQueryId !== props.queryResult?.queryId) {
    setPreviousQueryId(props.queryResult?.queryId);
    devToolsPanelRef.current?.contentWindow?.postMessage(props.queryResult);
  }

  return (
    <div className="h-full w-full flex flex-col">
      <div className="pb-2 relative rounded flex-1">
        <iframe allow="clipboard-write" className="absolute rounded h-full w-full" ref={devToolsPanelRef} src="/minified/" />
        {props.isLoading ? (
          <Overlay className="absolute inset-0 flex flex-row justify-center items-center">
            <Spinner />
          </Overlay>
        ) : null}
        {/* TODO: Support the devtools feature on Safari */}
        {BrowserUtils.isSafari() ? (
          <Overlay className="absolute h-full w-full flex flex-row justify-center items-center">
            <div className="select-none cursor-default text-zinc-500 flex flex-col justify-center items-center">
              <span>This feature does not work on Safari yet.</span>
              <span>Consider using a supported browser like Chrome or Firefox.</span>
            </div>
          </Overlay>
        ) : null}
      </div>
      {renderFooter()}
    </div>
  );

  function renderFooter(): React.ReactNode {
    if (!props.queryResult || props.queryResult.data.length === 0 || !props.queryResult.wasRowLimitExceeded) {
      return null;
    }

    return (
      <div className="w-full h-6 flex flex-row bg-amber-700 text-black text-xs items-center px-1">
        <span className="flex flex-row space-x-1">
          <TriangleAlertIcon className="text-amber-500" size={15} />
          <span>
            Row limit of {QueryUtils.QUERY_RESULT_MAX_ROWS} reached. Your query would have returned {props.queryResult.actualNumberOfRows} rows. Consider narrowing the time window
            or adding filters to reduce the number of rows returned.
          </span>
        </span>
      </div>
    );
  }
}

export interface ChromeDevToolsPanel {
  isLoading: boolean;
  queryResult: QueryResult | undefined;
}
