import * as React from "react";
import { ZapIcon, BlendIcon, PyramidIcon, ShapesIcon, StarIcon, CheckIcon } from "lucide-react";

import { NavLinks } from "NavLinks";

export function PricingPage(): React.ReactElement {
  return (
    <div className="w-full h-vh flex flex-col px-4 lg:px-0">
      <div className="flex justify-center">
        <NavLinks />
      </div>

      <div className="mt-12 flex justify-center">
        <div className="w-full max-w-xl flex flex-col items-center space-y-4">
          <div className="text-md text-center font-medium text-zinc-300">Predictable pricing that can scale</div>
          <div className="text-sm text-center max-w-xs font-medium text-zinc-500">Start monitoring for free, collaborate with your team, then scale to hundreds of containers</div>
        </div>
      </div>

      <div className="my-12 lg:my-24 flex justify-center">
        <div className="w-full max-w-5xl flex flex-col items-center justify-center space-y-5">
          <div
            className={[
              "flex flex-col lg:items-stretch lg:flex-row lg:justify-center",
              "space-y-8 lg:space-y-0 rounded-md",
              "border border-zinc-800 divide-y divide-y-1 lg:divide-y-0 lg:divide-x lg:divide-x-1 divide-zinc-800",
              "text-zinc-300",
            ].join(" ")}
          >
            <div className="group relative hover:brightness-[1.10] lg:basis-[25%] lg:grow-0 lg:shrink-0 w-full lg:w-[30%] flex flex-col p-6 space-y-6 lg:pb-12 overflow-hidden">
              <div className="!mt-0 z-[-5] opacity-[0.00] group-hover:opacity-[0.60] absolute top-[-20px] left-[10%] w-[80%] h-[40px] transition ease-in-out duration-[150ms] translate-y-0 group-hover:translate-y-[20px] rounded-full bg-zinc-100 blur-[96px]"></div>
              <div className="!mt-0 z-[-5] opacity-[0.00] group-hover:opacity-[0.40] absolute top-[-40px] left-[20%] w-[60%] h-[80px] transition ease-in-out duration-[300ms] translate-y-0 group-hover:translate-y-[40px] rounded-full bg-zinc-100 blur-[128px]"></div>
              <div className="flex flex-col space-y-4">
                <div className="flex justify-between items-center">
                  <div className="text-zinc-300 group-hover:text-zinc-100 text-md font-medium">Free</div>
                  <div className="relative w-8 h-8 opacity-[0.80] group-hover:opacity-[1.00] grayscale group-hover:grayscale-0">
                    <ZapIcon strokeWidth={1} className="h-[100%] w-[100%] absolute left-0 top-0 ease-in-out hidden group-hover:block blur-[8px] scale-[1.25] text-amber-500/70" />
                    <ZapIcon strokeWidth={1} className="h-[100%] w-[100%] absolute left-0 top-0 ease-in-out hidden group-hover:block blur-[8px] scale-[1.25] text-yellow-500/70" />
                    <ZapIcon
                      strokeWidth={1}
                      className="h-[100%] w-[100%] absolute left-0 top-0 ease-in-out duration-[150ms] rotate-[0deg] group-hover:rotate-[-4deg] scale-[1.00] group-hover:scale-[1.00] text-yellow-500/80"
                    />
                    <ZapIcon
                      strokeWidth={1}
                      className="h-[100%] w-[100%] absolute left-0 top-0 ease-in-out duration-[150ms] rotate-[0deg] group-hover:rotate-[+5deg] scale-[1.00] group-hover:scale-[0.90] text-amber-400/60"
                    />
                    <ZapIcon
                      strokeWidth={1}
                      className="h-[100%] w-[100%] absolute left-0 top-0 ease-in-out duration-[150ms] rotate-[0deg] group-hover:rotate-[-9deg] scale-[1.00] group-hover:scale-[0.70] text-amber-500/70"
                    />
                  </div>
                </div>
                <div className="text-zinc-500 text-xs">For hobbyists who need monitoring for their side projects, for free.</div>
              </div>
              <div className="flex justify-center items-center space-x-2">
                <div className="text-zinc-300 text-2xl">$0</div>
                <div className="text-zinc-600 text-sm flex items-center">per month</div>
              </div>
              <div className="flex justify-center">
                <a
                  href="/login"
                  className="w-full px-4 py-2 duration-[100ms] group-hover:scale-[1.03] group/button flex justify-center items-center space-x-2 rounded w-full text-xs font-medium bg-zinc-100 hover:brightness-[0.90] cursor-pointer text-zinc-700"
                >
                  <div>Get started</div>
                  <div className="duration-[100ms] translate-x-[-1px] group-hover/button:translate-x-[+1px]">&rarr;</div>
                </a>
              </div>
              <div className="pt-6 flex flex-col space-y-2 text-[13px] text-zinc-300">
                <span className="flex space-x-2 items-center">
                  <CheckIcon className="w-[12px] h-[12px]" strokeWidth={4} />
                  <span>&infin; requests</span>
                </span>
                <span className="flex space-x-2 items-center">
                  <CheckIcon className="w-[12px] h-[12px]" strokeWidth={4} />
                  <span>1 user</span>
                </span>
                <span className="flex space-x-2 items-center">
                  <CheckIcon className="w-[12px] h-[12px]" strokeWidth={4} />
                  <span>3 containers</span>
                </span>
                <span className="flex space-x-2 items-center">
                  <CheckIcon className="w-[12px] h-[12px]" strokeWidth={4} />
                  <span>3 days retention</span>
                </span>
              </div>
            </div>

            <div className="group relative hover:brightness-[1.10] lg:basis-[25%] lg:grow-0 lg:shrink-0 w-full lg:w-[30%] flex flex-col p-6 space-y-6 lg:pb-12 overflow-hidden">
              <div className="!mt-0 z-[-5] opacity-[0.00] group-hover:opacity-[0.60] absolute top-[-20px] left-[10%] w-[80%] h-[40px] transition ease-in-out duration-[150ms] translate-y-0 group-hover:translate-y-[20px] rounded-full bg-zinc-100 blur-[96px]"></div>
              <div className="!mt-0 z-[-5] opacity-[0.00] group-hover:opacity-[0.40] absolute top-[-40px] left-[20%] w-[60%] h-[80px] transition ease-in-out duration-[300ms] translate-y-0 group-hover:translate-y-[40px] rounded-full bg-zinc-100 blur-[128px]"></div>
              <div className="flex flex-col space-y-4">
                <div className="flex justify-between items-center">
                  <div className="text-zinc-300 group-hover:text-zinc-100 text-md font-medium">Team</div>
                  <div className="relative w-8 h-8 opacity-[0.60] group-hover:opacity-[1.00] grayscale group-hover:grayscale-0">
                    <BlendIcon strokeWidth={1} className="h-[100%] w-[100%] absolute left-0 top-0 ease-in-out hidden group-hover:block blur-[8px] scale-[1.25] text-rose-500/75" />
                    <BlendIcon strokeWidth={1} className="h-[100%] w-[100%] absolute left-0 top-0 ease-in-out hidden group-hover:block blur-[8px] scale-[1.25] text-cyan-500/75" />
                    <BlendIcon
                      strokeWidth={1}
                      className="h-[100%] w-[100%] absolute left-0 top-0 ease-in-out duration-[150ms] rotate-[0deg] group-hover:rotate-[+10deg] translate-x-[0px] translate-y-[0px] group-hover:translate-x-[+1px] group-hover:translate-y-[+1px] text-rose-500/80"
                    />
                    <BlendIcon
                      strokeWidth={1}
                      className="h-[100%] w-[100%] absolute left-0 top-0 ease-in-out duration-[150ms] rotate-[0deg] group-hover:rotate-[-10deg] translate-x-[0px] translate-y-[0px] group-hover:translate-x-[-1px] group-hover:translate-y-[-1px] text-cyan-500/80"
                    />
                  </div>
                </div>
                <div className="text-zinc-500 text-xs">For small teams looking for backend monitoring that's easy to use.</div>
              </div>
              <div className="flex justify-center items-center space-x-2">
                <div className="text-zinc-300 text-2xl">$25</div>
                <div className="text-zinc-600 text-sm flex items-center">per month</div>
              </div>
              <div className="flex justify-center">
                <a
                  href="/login"
                  className="w-full px-4 py-2 duration-[100ms] group-hover:scale-[1.03] group/button flex justify-center items-center space-x-2 rounded w-full text-xs font-medium bg-zinc-100 hover:brightness-[0.90] cursor-pointer text-zinc-700"
                >
                  <div>Get started</div>
                  <div className="duration-[100ms] translate-x-[-1px] group-hover/button:translate-x-[+1px]">&rarr;</div>
                </a>
              </div>
              <div className="pt-6 flex flex-col space-y-2 text-[13px] text-zinc-300">
                <span className="flex space-x-2 items-center">
                  <CheckIcon className="w-[12px] h-[12px]" strokeWidth={4} />
                  <span>&infin; requests</span>
                </span>
                <span className="flex space-x-2 items-center">
                  <CheckIcon className="w-[12px] h-[12px]" strokeWidth={4} />
                  <span>3 users</span>
                </span>
                <span className="flex space-x-2 items-center">
                  <CheckIcon className="w-[12px] h-[12px]" strokeWidth={4} />
                  <span>10 containers</span>
                </span>
                <span className="flex space-x-2 items-center">
                  <CheckIcon className="w-[12px] h-[12px]" strokeWidth={4} />
                  <span>7 days retention</span>
                </span>
                <span className="flex space-x-2 items-center">
                  <div>
                    <StarIcon className="hidden group-hover:block h-[12px] w-[12px] brightness-[1.20] text-zinc-100" strokeWidth={2} />
                    <CheckIcon className="block group-hover:hidden w-[12px] h-[12px]" strokeWidth={4} />
                  </div>
                  <span>Centralized billing</span>
                </span>
              </div>
            </div>

            <div className="group relative hover:brightness-[1.10] lg:basis-[25%] lg:grow-0 lg:shrink-0 w-full lg:w-[30%] flex flex-col p-6 space-y-6 lg:pb-12 overflow-hidden">
              <div className="!mt-0 z-[-5] opacity-[0.00] group-hover:opacity-[0.60] absolute top-[-20px] left-[10%] w-[80%] h-[40px] transition ease-in-out duration-[150ms] translate-y-0 group-hover:translate-y-[20px] rounded-full bg-zinc-100 blur-[96px]"></div>
              <div className="!mt-0 z-[-5] opacity-[0.00] group-hover:opacity-[0.40] absolute top-[-40px] left-[20%] w-[60%] h-[80px] transition ease-in-out duration-[300ms] translate-y-0 group-hover:translate-y-[40px] rounded-full bg-zinc-100 blur-[128px]"></div>
              <div className="flex flex-col space-y-4">
                <div className="flex justify-between items-center">
                  <div className="text-zinc-300 group-hover:text-zinc-100 text-md font-medium">Scale</div>
                  <div className="relative w-8 h-8 opacity-[0.60] group-hover:opacity-[1.00] grayscale group-hover:grayscale-0">
                    <PyramidIcon
                      strokeWidth={1}
                      className="h-[100%] w-[100%] absolute left-0 top-0 ease-in-out hidden group-hover:block blur-[8px] scale-[1.25] text-emerald-500"
                    />
                    <PyramidIcon strokeWidth={1} className="h-[100%] w-[100%] absolute left-0 top-0 ease-in-out hidden group-hover:block blur-[8px] scale-[1.25] text-green-500" />
                    <PyramidIcon
                      strokeWidth={1}
                      className="h-[100%] w-[100%] absolute left-0 top-0 ease-in-out duration-[150ms] rotate-[0deg] group-hover:rotate-[-1deg] scale-[1.00] group-hover:scale-[0.95] translate-x-[0px] group-hover:translate-x-[-1px] text-green-500/70"
                    />
                    <PyramidIcon
                      strokeWidth={1}
                      className="h-[100%] w-[100%] absolute left-0 top-0 ease-in-out duration-[150ms] rotate-[0deg] group-hover:rotate-[+7deg] scale-[1.00] group-hover:scale-[1.05] translate-x-[0px] group-hover:translate-x-[+1px] text-green-600/70"
                    />
                    <PyramidIcon
                      strokeWidth={1}
                      className="h-[100%] w-[100%] absolute left-0 top-0 ease-in-out duration-[150ms] rotate-[0deg] group-hover:rotate-[+11deg] scale-[1.00] group-hover:scale-[1.00] translate-y-[0px] group-hover:translate-y-[+1px] text-emerald-500/80"
                    />
                  </div>
                </div>
                <div className="text-zinc-500 text-xs">For growing teams with compliance requirements operating at scale.</div>
              </div>
              <div className="flex justify-center items-center space-x-2">
                <div className="text-zinc-300 text-2xl">$599</div>
                <div className="text-zinc-600 text-sm flex items-center">per month</div>
              </div>
              <div className="flex justify-center">
                <a
                  href="/login"
                  className="w-full px-4 py-2 duration-[100ms] group-hover:scale-[1.03] group/button flex justify-center items-center space-x-2 rounded w-full text-xs font-medium bg-zinc-100 hover:brightness-[0.90] cursor-pointer text-zinc-700"
                >
                  <div>Get started</div>
                  <div className="duration-[100ms] translate-x-[-1px] group-hover/button:translate-x-[+1px]">&rarr;</div>
                </a>
              </div>
              <div className="pt-6 flex flex-col space-y-2 text-[13px] text-zinc-300">
                <span className="flex space-x-2 items-center">
                  <CheckIcon className="w-[12px] h-[12px]" strokeWidth={4} />
                  <span>&infin; requests</span>
                </span>
                <span className="flex space-x-2 items-center">
                  <CheckIcon className="w-[12px] h-[12px]" strokeWidth={4} />
                  <span>20 users</span>
                </span>
                <span className="flex space-x-2 items-center">
                  <CheckIcon className="w-[12px] h-[12px]" strokeWidth={4} />
                  <span>100 containers</span>
                </span>
                <span className="flex space-x-2 items-center">
                  <CheckIcon className="w-[12px] h-[12px]" strokeWidth={4} />
                  <span>30 days retention</span>
                </span>
                <span className="flex space-x-2 items-center">
                  <CheckIcon className="w-[12px] h-[12px]" strokeWidth={4} />
                  <span>Centralized billing</span>
                </span>
                <span className="flex space-x-2 items-center">
                  <div>
                    <StarIcon className="hidden group-hover:block h-[12px] w-[12px] brightness-[1.20] text-zinc-100" strokeWidth={2} />
                    <CheckIcon className="block group-hover:hidden w-[12px] h-[12px]" strokeWidth={4} />
                  </div>
                  <span>SOC2 and HIPAA</span>
                </span>
                <span className="flex space-x-2 items-center">
                  <div>
                    <StarIcon className="hidden group-hover:block h-[12px] w-[12px] brightness-[1.20] text-zinc-100" strokeWidth={2} />
                    <CheckIcon className="block group-hover:hidden w-[12px] h-[12px]" strokeWidth={4} />
                  </div>
                  <span>SAML and OIDC</span>
                </span>
                <span className="flex space-x-2 items-center">
                  <div>
                    <StarIcon className="hidden group-hover:block h-[12px] w-[12px] brightness-[1.20] text-zinc-100" strokeWidth={2} />
                    <CheckIcon className="block group-hover:hidden w-[12px] h-[12px]" strokeWidth={4} />
                  </div>
                  <span>Private Slack channel</span>
                </span>
              </div>
            </div>

            <div className="group relative hover:brightness-[1.10] lg:basis-[25%] lg:grow-0 lg:shrink-0 w-full lg:w-[30%] flex flex-col p-6 space-y-6 lg:pb-12 overflow-hidden">
              <div className="!mt-0 z-[-5] opacity-[0.00] group-hover:opacity-[0.60] absolute top-[-20px] left-[10%] w-[80%] h-[40px] transition ease-in-out duration-[150ms] translate-y-0 group-hover:translate-y-[20px] rounded-full bg-zinc-100 blur-[96px]"></div>
              <div className="!mt-0 z-[-5] opacity-[0.00] group-hover:opacity-[0.40] absolute top-[-40px] left-[20%] w-[60%] h-[80px] transition ease-in-out duration-[300ms] translate-y-0 group-hover:translate-y-[40px] rounded-full bg-zinc-100 blur-[128px]"></div>
              <div className="flex flex-col space-y-4">
                <div className="flex justify-between items-center">
                  <div className="text-zinc-300 group-hover:text-zinc-100 text-md font-medium">Enterprise</div>
                  <div className="relative w-8 h-8 opacity-[0.60] group-hover:opacity-[1.00] grayscale group-hover:grayscale-0">
                    <ShapesIcon strokeWidth={1} className="h-[100%] w-[100%] absolute left-0 top-0 ease-in-out hidden group-hover:block blur-[8px] scale-[1.25] text-purple-500" />
                    <ShapesIcon strokeWidth={1} className="h-[100%] w-[100%] absolute left-0 top-0 ease-in-out hidden group-hover:block blur-[8px] scale-[1.25] text-fuchsia-500" />
                    <ShapesIcon
                      strokeWidth={1}
                      className="h-[100%] w-[100%] absolute left-0 top-0 ease-in-out duration-[150ms] rotate-[0deg] group-hover:rotate-[+3deg] scale-[1.00] group-hover:scale-[0.95] translate-y-[0px] group-hover:translate-y-[+1px] text-violet-500/80"
                    />
                    <ShapesIcon
                      strokeWidth={1}
                      className="h-[100%] w-[100%] absolute left-0 top-0 ease-in-out duration-[150ms] rotate-[0deg] group-hover:rotate-[-5deg] scale-[1.00] group-hover:scale-[1.05] translate-x-[0px] group-hover:translate-x-[-1px] text-purple-400/90"
                    />
                    <ShapesIcon
                      strokeWidth={1}
                      className="h-[100%] w-[100%] absolute left-0 top-0 ease-in-out duration-[150ms] rotate-[0deg] group-hover:rotate-[-9deg] scale-[1.00] group-hover:scale-[1.00] translate-x-[0px] group-hover:translate-x-[+1px] text-fuchsia-400/80"
                    />
                  </div>
                </div>
                <div className="text-zinc-500 text-xs">For larger organizations that need advanced or custom features.</div>
              </div>
              <div className="flex justify-center items-center space-x-2">
                <div className="text-zinc-300 text-2xl">Custom</div>
              </div>
              <div className="flex justify-center">
                <a
                  href="mailto:support@subtrace.dev"
                  className="w-full px-4 py-2 duration-[100ms] group/button flex justify-center items-center space-x-2 rounded w-full text-xs font-medium bg-zinc-800/60 outline outline-[1px] outline-zinc-800 hover:brightness-[0.90] cursor-pointer text-zinc-200"
                >
                  <div>Contact us</div>
                  <div className="duration-[100ms] translate-x-[-1px] group-hover/button:translate-x-[+1px]">&rarr;</div>
                </a>
              </div>
              <div className="pt-6 flex flex-col space-y-2 text-[13px] text-zinc-300">
                <span className="flex space-x-2 items-center">
                  <CheckIcon className="w-[12px] h-[12px]" strokeWidth={4} />
                  <span>&infin; requests</span>
                </span>
                <span className="flex space-x-2 items-center">
                  <CheckIcon className="w-[12px] h-[12px]" strokeWidth={4} />
                  <span>&infin; users</span>
                </span>
                <span className="flex space-x-2 items-center">
                  <CheckIcon className="w-[12px] h-[12px]" strokeWidth={4} />
                  <span>&infin; containers</span>
                </span>
                <span className="flex space-x-2 items-center">
                  <CheckIcon className="w-[12px] h-[12px]" strokeWidth={4} />
                  <span>&infin; days retention</span>
                </span>
                <span className="flex space-x-2 items-center">
                  <CheckIcon className="w-[12px] h-[12px]" strokeWidth={4} />
                  <span>Centralized billing</span>
                </span>
                <span className="flex space-x-2 items-center">
                  <CheckIcon className="w-[12px] h-[12px]" strokeWidth={4} />
                  <span>SOC2 and HIPAA</span>
                </span>
                <span className="flex space-x-2 items-center">
                  <CheckIcon className="w-[12px] h-[12px]" strokeWidth={4} />
                  <span>SAML and OIDC</span>
                </span>
                <span className="flex space-x-2 items-center">
                  <CheckIcon className="w-[12px] h-[12px]" strokeWidth={4} />
                  <span>Private Slack channel</span>
                </span>
                <span className="flex space-x-2 items-center">
                  <div>
                    <StarIcon className="hidden group-hover:block h-[12px] w-[12px] brightness-[1.20] text-zinc-100" strokeWidth={2} />
                    <CheckIcon className="block group-hover:hidden w-[12px] h-[12px]" strokeWidth={4} />
                  </div>
                  <span>On-premise support</span>
                </span>
                <span className="flex space-x-2 items-center">
                  <div>
                    <StarIcon className="hidden group-hover:block h-[12px] w-[12px] brightness-[1.20] text-zinc-100" strokeWidth={2} />
                    <CheckIcon className="block group-hover:hidden w-[12px] h-[12px]" strokeWidth={4} />
                  </div>
                  <span>Uptime SLAs</span>
                </span>
              </div>
            </div>
            <div className="lg:hidden"></div>
          </div>
        </div>
      </div>

      <div className="mt-12 flex justify-center">
        <div className="w-full max-w-xl flex flex-col items-center space-y-12">
          <div className="text-md text-center font-medium text-zinc-300">Frequently asked questions</div>
          <div className="w-full flex flex-col items-begin space-y-4 divide divide-y divide-zinc-800/80">
            <details className="group pt-4">
              <summary className="cursor-pointer text-sm text-zinc-400 group-hover:brightness-[1.10] font-medium py-2">Is there an annual pricing discount?</summary>
              <p className="text-sm text-zinc-500/90">
                Yes. Customers in the Scale and Enterprise plan may subscribe to Subtrace annually at a discounted price. Please{" "}
                <a className="text-zinc-400 hover:brightness-[1.10]" href="mailto:support@subtrace.dev">
                  contact sales
                </a>{" "}
                to discuss.
              </p>
            </details>

            <details className="group pt-4">
              <summary className="cursor-pointer text-sm text-zinc-400 group-hover:brightness-[1.10] font-medium py-2">Do you offer free trials?</summary>
              <p className="text-sm text-zinc-500/90">
                Yes. Customers who want to use Subtrace for commercial use will get a 14-day trial of the product with no limits. Others with personal use cases (e.g. side
                projects) will continue to have access to the free tier plan.
              </p>
            </details>

            <details className="group pt-4">
              <summary className="cursor-pointer text-sm text-zinc-400 group-hover:brightness-[1.10] font-medium py-2">Is there a request rate limit?</summary>
              <p className="text-sm text-zinc-500/90">
                All plans are subject to a 100 requests per second rate limit on average, but short bursts exceeding the rate limit are allowed. If you have applications with
                significantly higher baseline traffic, please{" "}
                <a className="text-zinc-400 hover:brightness-[1.10]" href="mailto:support@subtrace.dev">
                  contact support
                </a>{" "}
                to discuss.
              </p>
            </details>

            <details className="group pt-4">
              <summary className="cursor-pointer text-sm text-zinc-400 group-hover:brightness-[1.10] font-medium py-2">Have more questions about pricing?</summary>
              <p className="text-sm text-zinc-500/90">
                Please{" "}
                <a className="text-zinc-400 hover:brightness-[1.10]" href="mailto:support@subtrace.dev">
                  contact support
                </a>{" "}
                for any other questions related to pricing.
              </p>
            </details>
          </div>
        </div>
      </div>

      <div className="mt-48 flex justify-center items-center">
        <NavLinks />
      </div>
    </div>
  );
}
