import * as React from "react";

import * as Api from "ApiContracts/control/api/api";
import * as ApiUtils from "ApiUtils";
import * as CustomHooks from "CustomHooks";
import { HeartCrackIcon, CheckIcon, BlendIcon, PyramidIcon, ShapesIcon, XIcon, ZapIcon, Loader2Icon } from "lucide-react";
import { LogoIcon } from "Logo";

export function BillingPage(): React.ReactNode {
  const { orgId } = CustomHooks.useCurrentOrg();
  const [activeSubscription, setActiveSubscription] = React.useState<undefined | null | Api.GetBillingStatus_Subscription>(undefined);
  const [isCancelling, setCancelling] = React.useState(false);
  const [showCancelled, setShowCancelled] = React.useState(false);

  const refreshActiveSubscription = async (): Promise<void> => {
    const request: Api.GetBillingStatus_Request = { orgId };
    const response: Response = await ApiUtils.post("/api/GetBillingStatus", request);
    await ApiUtils.assertStatus(response, 200);
    const { error, activeSubscription }: Api.GetBillingStatus_Response = await response.json();
    if (error !== undefined) {
      throw new Error(`call /api/GetSubscription: ${error}`);
    }

    setActiveSubscription(activeSubscription ?? null);
  };

  const cancelSubscription = async (): Promise<void> => {
    if (!activeSubscription) {
      throw new Error(`cancelSubscription called with activeSubscription=${activeSubscription}`);
    }

    setCancelling(true);

    const request: Api.CancelSubscription_Request = { stripeSubscriptionId: activeSubscription.stripeSubscriptionId };
    const response: Response = await ApiUtils.post("/api/CancelSubscription", request);
    await ApiUtils.assertStatus(response, 200);

    refreshActiveSubscription();
    setShowCancelled(true);

    setCancelling(false);
  };

  React.useEffect(() => {
    if (activeSubscription === undefined) {
      refreshActiveSubscription();
    }
  });

  if (activeSubscription === undefined) {
    return (
      <div className="w-full h-vh flex flex-col px-4 lg:px-0">
        <div className="mt-12 flex justify-center">
          <Loader2Icon size={16} className="text-zinc-700 animate-spin" />
        </div>
      </div>
    );
  }

  return (
    <div className="w-full h-vh flex flex-col px-2">
      <div className="mt-12 flex justify-center">
        <div className="w-full max-w-4xl flex flex-col items-center justify-center space-y-5">{activeSubscription === null ? renderFree() : renderActive()}</div>
      </div>
    </div>
  );

  function renderFree(): React.ReactNode {
    return (
      <div className="flex flex-col space-y-4 items-center">
        <div className="text-zinc-300 text-md font-semibold text-center">Billing</div>
        <div className="text-xs text-center max-w-xs font-medium text-zinc-500">
          Upgrade to get the most out of Subtrace&mdash;more users, more containers, more retention, more everything.
        </div>
        <span></span>

        {showCancelled ? (
          <div className="px-4 py-1 w-full rounded text-zinc-400 text-sm bg-zinc-900/85 outline outline-[1px] outline-zinc-800 text-zinc-200 flex justify-between items-center">
            <div className="space-x-4 text-xs flex items-center">
              <HeartCrackIcon size={16} />
              <div>
                <span className="font-medium">Your plan was cancelled.</span>{" "}
                <span className="text-zinc-500/80">We're sorry to see you go, but thank you for trying Subtrace.</span>
              </div>
            </div>
            <button className="p-2 cursor-pointer text-zinc-600 hover:text-zinc-400" onClick={() => setShowCancelled(false)}>
              <XIcon size={16} />
            </button>
          </div>
        ) : null}

        <div
          className={[
            "flex flex-col lg:items-stretch lg:flex-row lg:justify-center",
            "space-y-8 lg:space-y-0 rounded-md",
            "border border-zinc-800 divide-y divide-y-1 lg:divide-y-0 lg:divide-x lg:divide-x-1 divide-zinc-800",
            "text-zinc-300",
          ].join(" ")}
        >
          <div className="bg-zinc-800 opacity-[0.50] group/ignore relative lg:basis-[25%] lg:grow-0 lg:shrink-0 w-full lg:w-[25%] flex flex-col p-6 space-y-6 lg:pb-12 overflow-hidden">
            <div className="!mt-0 z-[-5] opacity-[0.00] group-hover:opacity-[0.60] absolute top-[-20px] left-[10%] w-[80%] h-[40px] transition ease-in-out duration-[150ms] translate-y-0 group-hover:translate-y-[20px] rounded-full bg-zinc-100 blur-[96px]"></div>
            <div className="!mt-0 z-[-5] opacity-[0.00] group-hover:opacity-[0.40] absolute top-[-40px] left-[20%] w-[60%] h-[80px] transition ease-in-out duration-[300ms] translate-y-0 group-hover:translate-y-[40px] rounded-full bg-zinc-100 blur-[128px]"></div>
            <div className="flex flex-col space-y-4">
              <div className="flex justify-between items-center">
                <div className="text-zinc-300 group-hover:text-zinc-100 text-md font-medium">Free</div>
                <div className="relative w-8 h-8 opacity-[0.50] grayscale group-hover:grayscale-0">
                  <ZapIcon strokeWidth={1} className="h-[100%] w-[100%] absolute left-0 top-0 ease-in-out hidden group-hover:block blur-[8px] scale-[1.25] text-amber-500/70" />
                  <ZapIcon strokeWidth={1} className="h-[100%] w-[100%] absolute left-0 top-0 ease-in-out hidden group-hover:block blur-[8px] scale-[1.25] text-yellow-500/70" />
                  <ZapIcon
                    strokeWidth={1}
                    className="h-[100%] w-[100%] absolute left-0 top-0 ease-in-out duration-[150ms] rotate-[0deg] group-hover:rotate-[-4deg] scale-[1.00] group-hover:scale-[1.00] text-yellow-500/80"
                  />
                  <ZapIcon
                    strokeWidth={1}
                    className="h-[100%] w-[100%] absolute left-0 top-0 ease-in-out duration-[150ms] rotate-[0deg] group-hover:rotate-[+5deg] scale-[1.00] group-hover:scale-[0.90] text-amber-400/60"
                  />
                  <ZapIcon
                    strokeWidth={1}
                    className="h-[100%] w-[100%] absolute left-0 top-0 ease-in-out duration-[150ms] rotate-[0deg] group-hover:rotate-[-9deg] scale-[1.00] group-hover:scale-[0.70] text-amber-500/70"
                  />
                </div>
              </div>
              <div className="text-zinc-500 text-xs">For hobbyists who need monitoring for their side projects, for free.</div>
            </div>
            <div className="flex justify-center items-center space-x-2">
              <div className="text-zinc-300 text-2xl">$0</div>
              <div className="text-zinc-600 text-sm flex items-center">per month</div>
            </div>
            <div className="flex justify-center">
              <div className="h-10 w-full">
                <div className="w-full px-4 py-2 flex justify-center items-center space-x-2 rounded text-xs font-medium text-zinc-400">
                  <CheckIcon size={14} strokeWidth={3} />
                  <div>Active</div>
                </div>
              </div>
            </div>
          </div>
          <div className="group relative hover:brightness-[1.10] lg:basis-[25%] lg:grow-0 lg:shrink-0 w-full lg:w-[25%] flex flex-col p-6 space-y-6 lg:pb-12 overflow-hidden">
            <div className="!mt-0 z-[-5] opacity-[0.00] group-hover:opacity-[0.60] absolute top-[-20px] left-[10%] w-[80%] h-[40px] transition ease-in-out duration-[150ms] translate-y-0 group-hover:translate-y-[20px] rounded-full bg-zinc-100 blur-[96px]"></div>
            <div className="!mt-0 z-[-5] opacity-[0.00] group-hover:opacity-[0.40] absolute top-[-40px] left-[20%] w-[60%] h-[80px] transition ease-in-out duration-[300ms] translate-y-0 group-hover:translate-y-[40px] rounded-full bg-zinc-100 blur-[128px]"></div>
            <div className="flex flex-col space-y-4">
              <div className="flex justify-between items-center">
                <div className="text-zinc-300 group-hover:text-zinc-100 text-md font-medium">Team</div>
                <div className="relative w-8 h-8 opacity-[0.60] group-hover:opacity-[1.00] grayscale group-hover:grayscale-0">
                  <BlendIcon strokeWidth={1} className="h-[100%] w-[100%] absolute left-0 top-0 ease-in-out hidden group-hover:block blur-[8px] scale-[1.25] text-rose-500/75" />
                  <BlendIcon strokeWidth={1} className="h-[100%] w-[100%] absolute left-0 top-0 ease-in-out hidden group-hover:block blur-[8px] scale-[1.25] text-cyan-500/75" />
                  <BlendIcon
                    strokeWidth={1}
                    className="h-[100%] w-[100%] absolute left-0 top-0 ease-in-out duration-[150ms] rotate-[0deg] group-hover:rotate-[+10deg] translate-x-[0px] translate-y-[0px] group-hover:translate-x-[+1px] group-hover:translate-y-[+1px] text-rose-500/80"
                  />
                  <BlendIcon
                    strokeWidth={1}
                    className="h-[100%] w-[100%] absolute left-0 top-0 ease-in-out duration-[150ms] rotate-[0deg] group-hover:rotate-[-10deg] translate-x-[0px] translate-y-[0px] group-hover:translate-x-[-1px] group-hover:translate-y-[-1px] text-cyan-500/80"
                  />
                </div>
              </div>
              <div className="text-zinc-500 text-xs">For small teams looking for backend monitoring that's easy to use.</div>
            </div>
            <div className="flex justify-center items-center space-x-2">
              <div className="text-zinc-300 text-2xl">$25</div>
              <div className="text-zinc-600 text-sm flex items-center">per month</div>
            </div>
            <div className="flex justify-center">
              <a
                href={`/api/StripeRedirect?org_id=${orgId}&tier_name=team-20250212`}
                className="w-full px-4 py-2 duration-[100ms] group-hover:scale-[1.03] group/button flex justify-center items-center space-x-2 rounded text-xs font-medium bg-zinc-100 hover:brightness-[0.90] cursor-pointer text-zinc-700"
              >
                <div>Upgrade</div>
                <div className="duration-[100ms] translate-x-[-1px] group-hover/button:translate-x-[+1px]">&rarr;</div>
              </a>
            </div>
          </div>
          <div className="group relative hover:brightness-[1.10] lg:basis-[25%] lg:grow-0 lg:shrink-0 w-full lg:w-[25%] flex flex-col p-6 space-y-6 lg:pb-12 overflow-hidden">
            <div className="!mt-0 z-[-5] opacity-[0.00] group-hover:opacity-[0.60] absolute top-[-20px] left-[10%] w-[80%] h-[40px] transition ease-in-out duration-[150ms] translate-y-0 group-hover:translate-y-[20px] rounded-full bg-zinc-100 blur-[96px]"></div>
            <div className="!mt-0 z-[-5] opacity-[0.00] group-hover:opacity-[0.40] absolute top-[-40px] left-[20%] w-[60%] h-[80px] transition ease-in-out duration-[300ms] translate-y-0 group-hover:translate-y-[40px] rounded-full bg-zinc-100 blur-[128px]"></div>
            <div className="flex flex-col space-y-4">
              <div className="flex justify-between items-center">
                <div className="text-zinc-300 group-hover:text-zinc-100 text-md font-medium">Scale</div>
                <div className="relative w-8 h-8 opacity-[0.60] group-hover:opacity-[1.00] grayscale group-hover:grayscale-0">
                  <PyramidIcon strokeWidth={1} className="h-[100%] w-[100%] absolute left-0 top-0 ease-in-out hidden group-hover:block blur-[8px] scale-[1.25] text-emerald-500" />
                  <PyramidIcon strokeWidth={1} className="h-[100%] w-[100%] absolute left-0 top-0 ease-in-out hidden group-hover:block blur-[8px] scale-[1.25] text-green-500" />
                  <PyramidIcon
                    strokeWidth={1}
                    className="h-[100%] w-[100%] absolute left-0 top-0 ease-in-out duration-[150ms] rotate-[0deg] group-hover:rotate-[-1deg] scale-[1.00] group-hover:scale-[0.95] translate-x-[0px] group-hover:translate-x-[-1px] text-green-500/70"
                  />
                  <PyramidIcon
                    strokeWidth={1}
                    className="h-[100%] w-[100%] absolute left-0 top-0 ease-in-out duration-[150ms] rotate-[0deg] group-hover:rotate-[+7deg] scale-[1.00] group-hover:scale-[1.05] translate-x-[0px] group-hover:translate-x-[+1px] text-green-600/70"
                  />
                  <PyramidIcon
                    strokeWidth={1}
                    className="h-[100%] w-[100%] absolute left-0 top-0 ease-in-out duration-[150ms] rotate-[0deg] group-hover:rotate-[+11deg] scale-[1.00] group-hover:scale-[1.00] translate-y-[0px] group-hover:translate-y-[+1px] text-emerald-500/80"
                  />
                </div>
              </div>
              <div className="text-zinc-500 text-xs">For growing teams with compliance requirements operating at scale.</div>
            </div>
            <div className="flex justify-center items-center space-x-2">
              <div className="text-zinc-300 text-2xl">$599</div>
              <div className="text-zinc-600 text-sm flex items-center">per month</div>
            </div>
            <div className="flex justify-center">
              <a
                href={`/api/StripeRedirect?org_id=${orgId}&tier_name=scale-20250212`}
                className="w-full px-4 py-2 duration-[100ms] group-hover:scale-[1.03] group/button flex justify-center items-center space-x-2 rounded text-xs font-medium bg-zinc-100 hover:brightness-[0.90] cursor-pointer text-zinc-700"
              >
                <div>Upgrade</div>
                <div className="duration-[100ms] translate-x-[-1px] group-hover/button:translate-x-[+1px]">&rarr;</div>
              </a>
            </div>
          </div>
          <div className="group relative hover:brightness-[1.10] lg:basis-[25%] lg:grow-0 lg:shrink-0 w-full lg:w-[25%] flex flex-col p-6 space-y-6 lg:pb-12 overflow-hidden">
            <div className="!mt-0 z-[-5] opacity-[0.00] group-hover:opacity-[0.60] absolute top-[-20px] left-[10%] w-[80%] h-[40px] transition ease-in-out duration-[150ms] translate-y-0 group-hover:translate-y-[20px] rounded-full bg-zinc-100 blur-[96px]"></div>
            <div className="!mt-0 z-[-5] opacity-[0.00] group-hover:opacity-[0.40] absolute top-[-40px] left-[20%] w-[60%] h-[80px] transition ease-in-out duration-[300ms] translate-y-0 group-hover:translate-y-[40px] rounded-full bg-zinc-100 blur-[128px]"></div>
            <div className="flex flex-col space-y-4">
              <div className="flex justify-between items-center">
                <div className="text-zinc-300 group-hover:text-zinc-100 text-md font-medium">Enterprise</div>
                <div className="relative w-8 h-8 opacity-[0.60] group-hover:opacity-[1.00] grayscale group-hover:grayscale-0">
                  <ShapesIcon strokeWidth={1} className="h-[100%] w-[100%] absolute left-0 top-0 ease-in-out hidden group-hover:block blur-[8px] scale-[1.25] text-purple-500" />
                  <ShapesIcon strokeWidth={1} className="h-[100%] w-[100%] absolute left-0 top-0 ease-in-out hidden group-hover:block blur-[8px] scale-[1.25] text-fuchsia-500" />
                  <ShapesIcon
                    strokeWidth={1}
                    className="h-[100%] w-[100%] absolute left-0 top-0 ease-in-out duration-[150ms] rotate-[0deg] group-hover:rotate-[+3deg] scale-[1.00] group-hover:scale-[0.95] translate-y-[0px] group-hover:translate-y-[+1px] text-violet-500/80"
                  />
                  <ShapesIcon
                    strokeWidth={1}
                    className="h-[100%] w-[100%] absolute left-0 top-0 ease-in-out duration-[150ms] rotate-[0deg] group-hover:rotate-[-5deg] scale-[1.00] group-hover:scale-[1.05] translate-x-[0px] group-hover:translate-x-[-1px] text-purple-400/90"
                  />
                  <ShapesIcon
                    strokeWidth={1}
                    className="h-[100%] w-[100%] absolute left-0 top-0 ease-in-out duration-[150ms] rotate-[0deg] group-hover:rotate-[-9deg] scale-[1.00] group-hover:scale-[1.00] translate-x-[0px] group-hover:translate-x-[+1px] text-fuchsia-400/80"
                  />
                </div>
              </div>
              <div className="text-zinc-500 text-xs">For larger organizations that need advanced or custom features.</div>
            </div>
            <div className="flex justify-center items-center space-x-2">
              <div className="text-zinc-300 text-2xl">Custom</div>
            </div>
            <div className="flex justify-center">
              <div className="h-10 w-full">
                <a
                  href="mailto:support@subtrace.dev"
                  className="w-full px-4 py-2 duration-[100ms] group/button flex justify-center items-center space-x-2 rounded text-xs font-medium bg-zinc-800/60 outline outline-[1px] outline-zinc-800 hover:brightness-[0.90] cursor-pointer text-zinc-200"
                >
                  <div>Contact us</div>
                  <div className="duration-[100ms] translate-x-[-1px] group-hover/button:translate-x-[+1px]">&rarr;</div>
                </a>
              </div>
            </div>
          </div>
        </div>

        <span></span>
        <div className=" text-xs text-center max-w-xs text-zinc-500/80 mt-12 self-center">
          <span>See the</span>{" "}
          <a className="text-zinc-500 hover:text-zinc-400" href="/pricing" target="_blank">
            pricing page
          </a>{" "}
          <span>to learn more about each tier.</span>
        </div>
      </div>
    );
  }

  function renderActive(): React.ReactNode {
    if (!activeSubscription) {
      throw new Error(`renderActive called with activeSubscription=${activeSubscription}`);
    }

    return (
      <div className="flex w-full justify-center">
        <div className="p-8 space-y-4 w-full max-w-[26rem] flex flex-col justify-center items-center rounded outline outline-[1px] outline-zinc-900 bg-zinc-950">
          <div className="flex justify-center items-center py-2">
            <LogoIcon className="w-8 text-zinc-300" />
          </div>

          <div className="text-zinc-300 text-xl font-semibold">Subscribed!</div>

          <div className="text-zinc-400/80 text-xs text-center">
            <span>You are subscribed to the</span>{" "}
            <span className="text-zinc-300/80 font-medium">
              {(() => {
                switch (activeSubscription.tierName) {
                  case "team-20250212":
                    return "Team plan";
                  case "scale-20250212":
                    return "Scale plan";
                  default:
                    throw new Error(`unknown tier name: ${activeSubscription.tierName}`);
                }
              })()}
            </span>
            <span>.</span>
          </div>

          <div className="text-zinc-400/80 text-xs text-center">
            <span>You will be billed</span>{" "}
            <span className="text-zinc-300/80 font-medium">
              {(() => {
                switch (activeSubscription.tierName) {
                  case "team-20250212":
                    return "$25 every month";
                  case "scale-20250212":
                    return "$599 every month";
                  default:
                    throw new Error(`unknown tier name: ${activeSubscription.tierName}`);
                }
              })()}
            </span>
            <span>.</span>
          </div>

          <div className="w-full flex flex-col space-y-4">
            <div className="text-zinc-600 text-xs text-center">
              <span>This is your subscription ID. Please include this if you ever need to contact support with questions regarding billing.</span>
            </div>
            <div className="text-center text-zinc-300/90 font-medium font-mono text-[11px] rounded-sm bg-zinc-900/90 outline outline-[1px] outline-zinc-800/80 px-4 py-[6px] hover:brightness-[1.10] cursor-pointer">
              {activeSubscription.stripeSubscriptionId}
            </div>
          </div>

          <details className="w-full flex flex-col justify-center">
            <summary className="mx-auto cursor-pointer text-[10px] text-zinc-600 hover:brightness-[1.10] py-2">Cancel?</summary>
            <button
              disabled={isCancelling}
              onClick={() => cancelSubscription()}
              className="w-full px-4 py-2 duration-[100ms] group/button flex justify-center items-center space-x-2 rounded text-xs font-medium bg-zinc-800/60 outline outline-[1px] outline-zinc-800 hover:brightness-[0.90] cursor-pointer text-zinc-200 disabled:opacity-[0.50] disabled:cursor-progress"
            >
              {isCancelling ? (
                <Loader2Icon size={16} className="animate-spin" />
              ) : (
                <React.Fragment>
                  <div>Cancel</div>
                  <div className="duration-[100ms] translate-x-[-1px] group-hover/button:translate-x-[+1px]">&rarr;</div>
                </React.Fragment>
              )}
            </button>
          </details>
        </div>
      </div>
    );
  }
}
