import * as React from "react";
import { Loader2, ArrowRight, ShieldAlert } from "lucide-react";

import * as Api from "ApiContracts/control/api/api";
import * as ApiUtils from "ApiUtils";
import * as CustomHooks from "CustomHooks";
import { Overlay } from "Overlay";
import { SetupInstructions } from "SetupInstructions";
import * as pubsub from "ApiContracts/subtrace/pubsub/pubsub";

function DevTools(props: { url: string }) {
  const iframeId = React.useId();
  const [appliedFilter, setAppliedFilter] = React.useState<string>("");
  const [pendingFilter, setPendingFilter] = React.useState<string>("");
  const [filterError, setFilterError] = React.useState<string | null>(null);
  const [isApplying, setApplying] = React.useState<boolean>(false);

  const apply = () => {
    const iframe = document.getElementById(iframeId) as HTMLIFrameElement;
    iframe.contentWindow!.postMessage(
      {
        source: "iframer-bridge",
        method: "setFilter",
        args: pendingFilter,
      },
      "*",
    );
    setAppliedFilter(pendingFilter);
    setApplying(true);
  };

  React.useEffect(() => {
    const handler = (ev: MessageEvent) => {
      if (ev.data.source !== "iframer-bridge") {
        return;
      }

      console.log("iframer-bridge: parent: received", ev.data);
      try {
        switch (ev.data.method) {
          case "markApplying":
            setApplying(true);
            return;
          case "setFilterError":
            setFilterError(ev.data.args);
            setApplying(false);
            return;
          case "markApplied":
            setFilterError(null);
            setApplying(false);
            return;
          default:
            throw new Error("unknown method");
        }
      } catch (e) {
        console.log("iframer-bridge: parent: exception when handling", ev.data, e);
      }
    };

    window.addEventListener("message", handler);
    return () => {
      console.log("iframer-bridge: parent: removing handler", handler);
      window.removeEventListener("message", handler);
    };
  }, []);

  const commitHash = (window as any).subtrace.version.commitHash;

  return (
    <div className="w-full flex flex-col space-y-3 p-1">
      <div className="w-full rounded flex space-x-2">
        <input
          autoFocus
          type="text"
          onChange={(ev) => setPendingFilter(ev.target.value)}
          onKeyDown={(ev) => (ev.key === "Enter" ? apply() : null)}
          className="grow rounded font-mono text-[11px] font-medium placeholder:text-zinc-700 text-zinc-300 px-3 py-[6px] bg-zinc-900 border border-zinc-700/75 hover:border-zinc-600/75 focus:outline-0 focus:border-zinc-600"
          placeholder='example: request.method == "POST"'
        />
        <button
          onClick={apply}
          disabled={appliedFilter === pendingFilter}
          className="text-zinc-400 hover:text-zinc-300 disabled:text-zinc-600 flex justify-center items-center px-4 cursor-pointer"
        >
          {(() => {
            if (isApplying) {
              return <Loader2 className="h-[14px] w-[14px] animate-spin" />;
            }
            if (appliedFilter !== pendingFilter) {
              return <ArrowRight className="h-[14px] w-[14px]" />;
            }
            if (filterError) {
              return <ShieldAlert className="text-red-600 h-[14px] w-[14px]" />;
            }
            return <ArrowRight className="h-[14px] w-[14px]" />;
          })()}
        </button>
      </div>
      {filterError ? (
        <div className="flex items-center px-3 border-l border-transparent">
          <div className="w-full max-w-full text-zinc-500 font-mono text-[10px] font-medium truncate text-ellipsis overflow-hidden">{filterError}</div>
        </div>
      ) : null}
      <iframe
        id={iframeId}
        sandbox="allow-modals allow-popups allow-scripts allow-same-origin allow-downloads"
        allow="clipboard-write"
        className="rounded h-full w-full"
        src={`/iframer?commit=${commitHash}#url=${encodeURIComponent(props.url)}`}
      />
    </div>
  );
}

export function LivePage(props: LivePageProps): React.ReactNode {
  const [url, setUrl] = React.useState<string | null>(null);

  const { currentNamespace } = CustomHooks.useNamespaceManager();
  const { orgId } = CustomHooks.useCurrentOrg();
  const { namespaceId } = currentNamespace;
  const { setupStatus, onSetupStatusChange } = props;

  React.useEffect(() => {
    const controller: AbortController = new AbortController();
    const { signal } = controller;

    async function populateSetupStatus(): Promise<void> {
      if (setupStatus != null) {
        return;
      }

      const request: Api.HasSeenPublisher_Request = { orgId };
      try {
        const response: Response = await ApiUtils.post("/api/HasSeenPublisher", request, { signal });
        await ApiUtils.assertStatus(response, 200);
        const { hasSeenPublisher }: Api.HasSeenPublisher_Response = await response.json();

        if (!hasSeenPublisher) {
          const request: Api.GenerateNamespaceToken_Request = { namespaceId };
          const response: Response = await ApiUtils.post("/api/GenerateNamespaceToken", request, { signal });
          await ApiUtils.assertStatus(response, 200);
          const { token }: Api.GenerateNamespaceToken_Response = await response.json();

          onSetupStatusChange({ isComplete: false, namespaceToken: token });
        } else {
          onSetupStatusChange({ isComplete: true });
        }
      } catch (error: unknown) {
        if (signal.aborted) {
          // Do nothing, the API call was canceled
        } else {
          throw error;
        }
      }
    }

    populateSetupStatus();

    return (): void => {
      controller.abort("Cleaning up effect populateSetupStatus");
    };
  }, [namespaceId, onSetupStatusChange, orgId, setupStatus]);

  React.useEffect(() => {
    if (url) {
      return;
    }

    (async (): Promise<void> => {
      const body = JSON.stringify(pubsub.JoinSubscriber_Request.toJSON({ namespaceId }));
      const resp = await fetch(`/api/JoinSubscriber`, { method: "POST", body });
      const json = await resp.json();
      const { websocketUrl } = pubsub.JoinSubscriber_Response.fromJSON(json);
      setUrl(websocketUrl);
    })();
  });

  if (setupStatus == null) {
    return null;
  }

  if (!url) {
    return <React.Fragment />;
  }

  return (
    <div className="m-2 flex w-full rounded-md">
      {setupStatus.isComplete ? null : (
        <div className="absolute inset-0">
          <div className="absolute flex h-full w-full z-20 justify-center items-center self-center rounded-md">
            {setupStatus.namespaceToken != null ? (
              <SetupInstructions namespaceToken={setupStatus.namespaceToken} onClose={() => onSetupStatusChange({ isComplete: true })} orgId={orgId} />
            ) : null}
          </div>
          <Overlay className="absolute inset-0 w-full h-full backdrop-blur-md backdrop-brightness-[0.85] rounded" />
        </div>
      )}
      <DevTools url={url} />
    </div>
  );
}

export type LivePageSetupStatus =
  | {
      isComplete: true;
    }
  | {
      isComplete: false;
      namespaceToken: string;
    };

export interface LivePageProps {
  setupStatus: LivePageSetupStatus | undefined;

  onSetupStatusChange: (setupStatus: LivePageSetupStatus) => void;
}
