import * as React from "react";
import { DollarSign, Crown, BookOpen, Github, Twitter } from "lucide-react";

import { LogoIcon } from "Logo";
import { Glitch } from "Glitch";

const Discord = (props: { size: number }) => (
  <span className={`w-[${props.size}px] h-[${props.size}px] flex justify-center items-center`}>
    <svg xmlns="http://www.w3.org/2000/svg" width={127.14} height={96.36} viewBox="0 0 127.14 96.36">
      <path
        stroke="currentColor"
        strokeWidth={10}
        d="M107.7,8.07A105.15,105.15,0,0,0,81.47,0a72.06,72.06,0,0,0-3.36,6.83A97.68,97.68,0,0,0,49,6.83,72.37,72.37,0,0,0,45.64,0,105.89,105.89,0,0,0,19.39,8.09C2.79,32.65-1.71,56.6.54,80.21h0A105.73,105.73,0,0,0,32.71,96.36,77.7,77.7,0,0,0,39.6,85.25a68.42,68.42,0,0,1-10.85-5.18c.91-.66,1.8-1.34,2.66-2a75.57,75.57,0,0,0,64.32,0c.87.71,1.76,1.39,2.66,2a68.68,68.68,0,0,1-10.87,5.19,77,77,0,0,0,6.89,11.1A105.25,105.25,0,0,0,126.6,80.22h0C129.24,52.84,122.09,29.11,107.7,8.07ZM42.45,65.69C36.18,65.69,31,60,31,53s5-12.74,11.43-12.74S54,46,53.89,53,48.84,65.69,42.45,65.69Zm42.24,0C78.41,65.69,73.25,60,73.25,53s5-12.74,11.44-12.74S96.23,46,96.12,53,91.08,65.69,84.69,65.69Z"
      />
    </svg>
  </span>
);

const DesktopLinks = () => (
  <div className="flex justify-center bg-zinc-900/70 px-3 rounded-full outline outline-[1px] outline-zinc-700/70 mx-2">
    <a className="relative group cursor-pointer px-3 py-2 text-xs text-zinc-400/80 hover:text-zinc-300 font-medium" href="/pricing">
      <span>Pricing</span>
      <div className="absolute bottom-[-24px] left-0 w-full">
        <Glitch icon={<DollarSign size={24} />} />
      </div>
    </a>
    <a className="relative group cursor-pointer px-3 py-2 text-xs text-zinc-400/80 hover:text-zinc-300 font-medium" href="/customers">
      <span>Customers</span>
      <div className="absolute bottom-[-24px] left-0 w-full">
        <Glitch icon={<Crown size={24} />} />
      </div>
    </a>
    <a className="relative group cursor-pointer px-3 py-2 text-xs text-zinc-400/80 hover:text-zinc-300 font-medium" href="https://docs.subtrace.dev">
      <span>Docs</span>
      <div className="absolute bottom-[-24px] left-0 w-full">
        <Glitch icon={<BookOpen size={24} />} />
      </div>
    </a>
    <a className="relative group cursor-pointer px-3 py-2 text-xs text-zinc-400/80 hover:text-zinc-300 font-medium" href="/discord">
      <span>Discord</span>
      <div className="absolute bottom-[-24px] left-0 w-full">
        <Glitch icon={<Discord size={24} />} />
      </div>
    </a>
    <a className="relative group cursor-pointer px-3 py-2 text-xs text-zinc-400/80 hover:text-zinc-300 font-medium" href="https://github.com/subtrace/subtrace">
      <span>Github</span>
      <div className="absolute bottom-[-24px] left-0 w-full">
        <Glitch icon={<Github size={24} />} />
      </div>
    </a>
    <a className="relative group cursor-pointer px-3 py-2 text-xs text-zinc-400/80 hover:text-zinc-300 font-medium" href="https://twitter.com/subtrace_dev">
      <span>Twitter</span>
      <div className="absolute bottom-[-24px] left-0 w-full">
        <Glitch icon={<Twitter size={24} />} />
      </div>
    </a>
  </div>
);

const MobileLinks = () => (
  <div className="w-full max-w-full overflow-x-scroll overflow-hide-scrollbar bg-zinc-950 outline outline-[1px] outline-zinc-800/90">
    <div className="mx-auto w-fit min-w-fit flex justify-begin items-center px-8">
      <a className="cursor-pointer px-[12px] py-2 text-xs text-zinc-400/80 hover:text-zinc-300 font-medium" href="/pricing">
        Pricing
      </a>
      <a className="cursor-pointer px-[12px] py-2 text-xs text-zinc-400/80 hover:text-zinc-300 font-medium" href="/customers">
        Customers
      </a>
      <a target="_blank" className="cursor-pointer px-[12px] py-2 text-xs text-zinc-400/80 hover:text-zinc-300 font-medium" href="https://docs.subtrace.dev">
        Docs
      </a>
      <a target="_blank" className="cursor-pointer px-[12px] py-2 text-xs text-zinc-400/80 hover:text-zinc-300 font-medium" href="/discord">
        Discord
      </a>
      <a target="_blank" className="cursor-pointer px-[12px] py-2 text-xs text-zinc-400/80 hover:text-zinc-300 font-medium" href="https://github.com/subtrace/subtrace">
        Github
      </a>
      <a target="_blank" className="cursor-pointer px-[12px] py-2 text-xs text-zinc-400/80 hover:text-zinc-300 font-medium" href="https://twitter.com/subtrace_dev">
        Twitter
      </a>
    </div>
  </div>
);

export function NavLinks() {
  return (
    <div className="w-full max-w-5xl flex flex-col items-center py-8 px-8 md:px-8">
      <div className="w-full flex justify-center items-center">
        <div className="basis-[6rem] flex justify-begin items-center">
          <a className="p-4 -m-4 group cursor-pointer text-zinc-300 flex justify-center items-center" href="/">
            <div className="relative w-[24px] h-[24px] scale-[1.30]">
              <div className="z-0 absolute top-0 left-[10%] w-[80%] h-full rounded-full bg-zinc-300 blur-[32px]" />
              <div className="z-10 text-zinc-200 w-full h-full absolute top-0 left-0 flex transition-all delay-[50ms] duration-[150ms] ease-in opacity-[1.00] group-hover:opacity-[0.50] justify-center">
                <LogoIcon />
              </div>
              <div className="scale-[1.10] z-5 w-full h-full absolute top-0 left-0 flex transition-all delay-[50ms] duration-[250ms] ease-in-out blur-[1px] group-hover:blur-[0px] opacity-[0.00] group-hover:opacity-[1.00] justify-center items-center">
                <Glitch icon={<LogoIcon />} />
              </div>
            </div>
          </a>
        </div>

        <div className="flex justify-center items-center grow">
          <div className="hidden md:flex justify-center items-center w-full">
            <DesktopLinks />
          </div>
        </div>

        <div className="basis-[6rem] flex justify-end items-center">
          <a
            href="/login"
            className="max-w-fit whitespace-pre group/button px-4 py-2 flex justify-center items-center space-x-2 rounded w-full text-xs font-medium bg-zinc-100 hover:brightness-[0.90] cursor-pointer text-zinc-700"
          >
            <div>Start</div>
            <div className="duration-[100ms] translate-x-[-1px] group-hover/button:translate-x-[+1px]">&rarr;</div>
          </a>
        </div>
      </div>

      <div className="flex justify-center md:hidden w-screen mt-8 relative">
        <MobileLinks />
        <div className="absolute top-0 left-0 h-full w-10 bg-gradient-to-r from-zinc-950 via-zinc-950/85 to-transparent" />
        <div className="absolute top-0 right-0 h-full w-10 bg-gradient-to-l from-zinc-950 via-zinc-950/85 to-transparent" />
      </div>
    </div>
  );
}
