import * as React from "react";
import { CheckIcon, CopyIcon, RocketIcon, ShapesIcon } from "lucide-react";

import * as Api from "ApiContracts/control/api/api";
import * as ApiUtils from "ApiUtils";
import * as StyleUtils from "Utils/StyleUtils";
import * as Verify from "Utils/Verify";
import { Button } from "DesignComponents/Button";

import GoLogo from "../images/go_logo.svg";
import NextLogo from "../images/nextjs_logo.svg";
import NodeLogo from "../images/nodejs_logo.svg";
import PythonLogo from "../images/python_logo.svg";

export function SetupInstructions(props: SetupInstructionsProps): React.ReactNode {
  const [hasSeenPublisher, setHasSeenPublisher] = React.useState<boolean>(false);

  const [stage, setStage] = React.useState<"first" | "second">("first");
  const [selectedLanguage, setSelectedLanguage] = React.useState<SupportedLangauge | undefined>(undefined);

  const { namespaceToken, orgId } = props;

  React.useEffect(() => {
    const controller: AbortController = new AbortController();
    const { signal } = controller;

    async function checkForPublisher(): Promise<void> {
      try {
        const request: Api.HasSeenPublisher_Request = { orgId };
        const response: Response = await ApiUtils.post("/api/HasSeenPublisher", request, { signal });
        const result: Api.HasSeenPublisher_Response = await response.json();
        if (result.error == null && result.hasSeenPublisher) {
          setHasSeenPublisher(true);
          clearInterval(intervalId);
        }
      } catch (error: unknown) {
        if (signal.aborted) {
          // Do nothing, the API call was canceled
          return;
        }
        throw error;
      }
    }

    // Make one immediate call before starting the interval loop
    checkForPublisher();
    const intervalId: number = setInterval(() => {
      checkForPublisher();
    }, 1000);

    return (): void => {
      clearInterval(intervalId);
      controller.abort();
    };
  }, [orgId]);

  if (stage === "first") {
    return <div className="max-w-[30rem] py-8 px-12 rounded-xl bg-black border border-zinc-800/50 outline outline-[1px] outline-black">{renderFirstStage()}</div>;
  }

  if (stage === "second") {
    return <div className="max-w-[30rem] p-8 rounded-xl bg-black border border-zinc-800/50 outline outline-[1px] outline-black">{renderSecondStage()}</div>;
  }

  Verify.isNever(stage);

  function renderFirstStage(): React.ReactNode {
    return (
      <div className={StyleUtils.mergeClassNames("flex flex-col text-xs text-zinc-500 space-y-6 items-center", props.className)}>
        <div className="flex flex-col items-center font-medium text-[16px] text-zinc-400">Connect your backend</div>
        <div className="flex flex-col space-y-4">
          <div className="text-xs text-zinc-600">
            <span>Select your tech stack to see how you can connect your backend.</span>
            <span> </span>
            <span>Check out</span>
            <span> </span>
            <a className="text-zinc-500 font-medium" href="https://docs.subtrace.dev/dashboard" target="_blank">
              our docs
            </a>
            <span> </span>
            <span>for more information.</span>
          </div>
          <div className="flex flex-col space-y-3">
            <div className="flex flex-col space-y-4">{renderTabs()}</div>
            <Button
              className="text-xs text-zinc-700 hover:text-zinc-600 font-medium w-fit self-center bg-transparent hover:bg-transparent hover:brightness-110 border-none"
              label="I'll do this later"
              onClick={() => {
                props.onClose();
              }}
            />
          </div>
        </div>
      </div>
    );
  }

  function renderSecondStage(): React.ReactNode {
    return (
      <div className={StyleUtils.mergeClassNames("flex flex-col text-xs text-zinc-500 space-y-8", props.className)}>
        <div className="flex flex-col space-y-8">
          <div className="flex justify-between items-center">
            <div className="font-medium text-zinc-400 text-[16px]">Connect your backend</div>
            <select
              className="self-end text-[11px] text-zinc-400 bg-zinc-700 border-r border-r-[8px] border-transparent px-2 py-1 rounded focus-visible:outline-0"
              value={selectedLanguage}
              onChange={(event) => setSelectedLanguage(event.target.value as SupportedLangauge)}
            >
              <option value={SupportedLangauge.Python}>Python</option>
              <option value={SupportedLangauge.Node}>Node.js</option>
              <option value={SupportedLangauge.NextJs}>Next.js</option>
              <option value={SupportedLangauge.Go}>Go</option>
              <option value={SupportedLangauge.Other}>Other</option>
            </select>
          </div>
          {renderLanguageInstructions()}
        </div>
        {renderConnectionStatus()}
        <div className="flex flex-col space-y-4">
          <Button
            className="w-fit self-center"
            disabled={!hasSeenPublisher}
            label="Finish"
            onClick={() => {
              props.onClose();
            }}
            showArrow
          />
          <div className="h-4 self-center">
            {hasSeenPublisher ? null : (
              <Button
                className="text-xs text-zinc-600 font-medium w-fit self-center bg-transparent hover:bg-transparent hover:brightness-110 border-none"
                label="I'll do this later"
                onClick={() => {
                  props.onClose();
                }}
              />
            )}
          </div>
        </div>
      </div>
    );
  }

  function renderConnectionStatus(): React.ReactNode {
    return (
      <div className="self-center flex flex-col space-y-3 h-20 items-center">
        {hasSeenPublisher ? (
          <React.Fragment>
            <RocketIcon className="animate-rocket-launch text-zinc-400 h-8" strokeWidth={0.75} size={32} />
            <div className="flex flex-col items-center space-y-1 h-4">
              <span className="text-zinc-600 text-xs font-medium">Connected!</span>
              <span className="text-zinc-600 text-xs font-medium">Your app's requests will show up on the dashboard.</span>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div className="h-8 flex justify-center items-center relative">
              <div className="absolute rounded-full h-3 w-3 bg-zinc-700 animate-ping" />
              <div className="absolute rounded-full h-1.5 w-1.5 bg-zinc-600 animate-pulse" />
            </div>
            <div className="text-zinc-600 text-xs font-medium flex flex-col space-y-1 items-center">
              <span>Waiting for connections&hellip;</span>
              <span>
                <span className="text-zinc-600">Having trouble? See </span>
                <a className="text-zinc-500 font-medium" href="https://docs.subtrace.dev/dashboard" target="_blank">
                  our docs
                </a>
              </span>
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }

  function renderLanguageInstructions(): React.ReactNode {
    const installWithCurlInstruction: React.ReactNode = (
      <div className="flex flex-col space-y-2 overflow-x-auto">
        <div>Install the latest version of Subtrace:</div>
        <CodeBlock code='curl -fsSLO "https://subtrace.dev/download/latest/$(uname -s)/$(uname -m)/subtrace" && chmod +x ./subtrace' />
      </div>
    );

    const setEnvVarInstruction: React.ReactNode = (
      <div className="flex flex-col space-y-2 overflow-x-auto">
        <div>
          Set the <span className="font-mono text-[11px] text-zinc-300 px-1 rounded">SUBTRACE_TOKEN</span> environment variable:
        </div>
        <CodeBlock code={`export SUBTRACE_TOKEN=${namespaceToken}`} />
      </div>
    );

    switch (selectedLanguage) {
      case SupportedLangauge.Go:
        return (
          <div className="flex flex-col space-y-6">
            {installWithCurlInstruction}
            {setEnvVarInstruction}
            <div className="flex flex-col space-y-2">
              <div>Start your app the way you normally do, but with Subtrace:</div>
              <CodeBlock code="./subtrace run -- go run ." />
            </div>
          </div>
        );

      case SupportedLangauge.NextJs:
        return (
          <div className="flex flex-col space-y-6">
            {installWithCurlInstruction}
            {setEnvVarInstruction}
            <div className="flex flex-col space-y-2">
              <div>Start your app the way you normally do, but with Subtrace:</div>
              <CodeBlock code="./subtrace run -- next start" />
            </div>
          </div>
        );

      case SupportedLangauge.Node:
        return (
          <div className="flex flex-col space-y-6">
            {installWithCurlInstruction}
            {setEnvVarInstruction}
            <div className="flex flex-col space-y-2">
              <div>Start your app the way you normally do, but with Subtrace:</div>
              <CodeBlock code="./subtrace run -- node ./app.js" />
            </div>
          </div>
        );

      case SupportedLangauge.Python:
        return (
          <div className="flex flex-col space-y-6">
            <div className="flex flex-col space-y-6">
              <div className="flex flex-col space-y-2 overflow-x-auto">
                <div>Install the Subtrace package:</div>
                <CodeBlock code="pip install subtrace" />
              </div>
              <div className="flex flex-col space-y-2">
                <div>Add the following import line to your code:</div>
                <CodeBlock code="import subtrace" />
              </div>
              {setEnvVarInstruction}
            </div>
            <div className="flex flex-col space-y-2">
              <div>And start your app!</div>
            </div>
          </div>
        );

      case SupportedLangauge.Other:
        return (
          <div className="flex flex-col space-y-6">
            {installWithCurlInstruction}
            {setEnvVarInstruction}
            <div className="flex flex-col space-y-2">
              <div>Start your app the way you normally do, but with Subtrace:</div>
              <CodeBlock code="./subtrace run -- my-app" />
            </div>
          </div>
        );

      case undefined:
        return null;

      default:
        Verify.isNever(selectedLanguage);
    }
  }

  function renderTabs(): React.ReactNode {
    return (
      <div className="flex p-2 text-zinc-400 justify-center items-center">
        <button
          className="flex flex-col px-1 -mx-1 space-y-2 items-center h-20 w-20 p-4 hover:bg-zinc-900 rounded"
          onClick={() => {
            setSelectedLanguage(SupportedLangauge.Python);
            setStage("second");
          }}
        >
          <div className="p-[2px] h-6 w-6 flex items-center justify-center">
            <img src={PythonLogo} />
          </div>
          <span className="text-xs">Python</span>
        </button>
        <button
          className="flex flex-col px-1 -mx-1 space-y-2 items-center h-20 w-20 p-4 hover:bg-zinc-900 rounded"
          onClick={() => {
            setSelectedLanguage(SupportedLangauge.Node);
            setStage("second");
          }}
        >
          <div className="p-[2px] h-6 w-6 flex items-center justify-center">
            <img src={NodeLogo} />
          </div>
          <span className="text-xs">Node.js</span>
        </button>
        <button
          className="flex flex-col px-1 -mx-1 space-y-2 items-center h-20 w-20 p-4 hover:bg-zinc-900 rounded"
          onClick={() => {
            setSelectedLanguage(SupportedLangauge.NextJs);
            setStage("second");
          }}
        >
          <div className="p-1 h-6 w-6 flex items-center justify-center">
            <img src={NextLogo} />
          </div>
          <span className="text-xs">Next.js</span>
        </button>
        <button
          className="flex flex-col px-1 -mx-1 space-y-2 items-center h-20 w-20 p-4 hover:bg-zinc-900 rounded"
          onClick={() => {
            setSelectedLanguage(SupportedLangauge.Go);
            setStage("second");
          }}
        >
          <div className="h-6 w-6 flex items-center justify-center">
            <img src={GoLogo} />
          </div>
          <span className="text-xs">Go</span>
        </button>
        <div style={{ background: "linear-gradient(to bottom, transparent, #27272ac0 30%, #27272ac0 70%, transparent 100%)" }} className="h-20 w-[1px] mx-3"></div>
        <button
          className="flex flex-col px-1 -mx-1 space-y-2 items-center h-20 w-20 p-4 hover:bg-zinc-900 rounded"
          onClick={() => {
            setSelectedLanguage(SupportedLangauge.Other);
            setStage("second");
          }}
        >
          <div className="p-[4px] h-6 w-6 flex items-center justify-center">
            <ShapesIcon className="text-zinc-700" />
          </div>
          <span className="text-xs">Other</span>
        </button>
      </div>
    );
  }
}

function CodeBlock(props: { code: string }): React.ReactNode {
  const [showCheck, setShowCheck] = React.useState<boolean>(false);
  const [timeoutId, setTimeoutId] = React.useState<number | undefined>(undefined);

  React.useEffect(
    () => () => {
      clearTimeout(timeoutId);
    },
    [timeoutId],
  );

  return (
    <div className="group relative flex flex-row font-mono text-[11px] text-zinc-300 bg-zinc-900 px-4 py-2 rounded">
      <span className="grow whitespace-nowrap overflow-x-auto overflow-hide-scrollbar">{props.code}</span>
      <button
        className="hidden group-hover:flex rounded justify-center items-center absolute right-0 top-0 h-full text-zinc-700 hover:text-zinc-600 py-1 px-4 backdrop-blur-lg backdrop-brightness-[0.70]"
        onClick={copy}
      >
        {showCheck ? <CheckIcon className="text-zinc-400" size={15} /> : <CopyIcon size={15} />}
      </button>
    </div>
  );

  async function copy(): Promise<void> {
    await navigator.clipboard.writeText(props.code);

    setShowCheck(true);
    setTimeoutId(
      setTimeout(() => {
        setShowCheck(false);
      }, 2000),
    );
  }
}

export interface SetupInstructionsProps {
  className?: string;
  namespaceToken: string;
  orgId: string;

  onClose: () => void;
}

const enum SupportedLangauge {
  Go = "Go",
  NextJs = "NextJs",
  Node = "Node",
  Python = "Python",

  Other = "Other",
}
