import * as React from "react";
import * as ReactDomClient from "react-dom/client";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import * as Api from "ApiContracts/control/api/api";
import * as ApiUtils from "ApiUtils";
import * as CustomHooks from "CustomHooks";
import * as NamespaceUtils from "NamespaceUtils";
import * as OrgUtils from "OrgUtils";
import * as StrictUtils from "Utils/StrictUtils";
import * as SubtraceTagsBase from "SubtraceTagsBase";
import { AppErrorBoundary } from "AppErrorBoundary";
import { Deferred } from "Deferred";
import { DialogContext } from "DialogContext";
import { DialogManager } from "DialogManager";
import { LandingPage } from "LandingPage";
import { CustomersPage } from "CustomersPage";
import { LinkPage } from "LinkPage";
import { PricingPage } from "PricingPage";
import { LoginPage } from "./LoginPage";
import { NamespaceContext } from "NamespaceContext";
import { NamespaceManager } from "NamespaceManager";
import { Navigation } from "Navigation";
import { Overlay } from "Overlay";
import { RequestsPage } from "RequestsPage";
import { BillingPage } from "BillingPage";
import { LivePage, LivePageSetupStatus } from "LivePage";
import { TokensPage } from "TokensPage";
import { User } from "User";
import { UsersPage } from "UsersPage";

import "./style.css";
import { OrgContext } from "OrgContext";

const rootElement: Element = document.getElementById("root")!;
ReactDomClient.createRoot(rootElement).render(<App />);

const parseUrlRegex: RegExp = new RegExp(/\/dashboard\/(?<namespace>[\w-]*)\/(?<rest>.*)/);

function getNamespaceFromUrl() {
  return parseUrlRegex.exec(window.location.pathname)?.groups?.namespace;
}

function App(): React.ReactNode {
  const [dialogState, setDialogState] = React.useState<DialogState>({ isDialogOpen: false });
  let deferred: Deferred<any>;

  const dialogManager: DialogManager = {
    show: (dialogComponent: React.ReactNode) => {
      deferred = new Deferred();

      setDialogState({
        isDialogOpen: true,
        dialogComponent,
      });

      return deferred.promise;
    },
    hide: (result: any) => {
      setDialogState({ isDialogOpen: false });
      deferred.resolve(result);
    },
  };

  const currentUser: User | undefined = CustomHooks.useCurrentUser();
  const [org, setOrg] = React.useState<Api.ListOrgs_Item | undefined>(undefined);
  const [namespaces, setNamespaces] = React.useState<Api.ListNamespaces_Item[] | undefined>(undefined);
  const [currentNamespace, setCurrentNamespace] = React.useState<Api.ListNamespaces_Item | undefined>(undefined);

  // Defining this in index.tsx so it persists for the lifetime of the app.
  const [livePageSetupStatus, setLivePageSetupStatus] = React.useState<LivePageSetupStatus | undefined>(undefined);

  React.useEffect(() => {
    (async () => {
      if (currentUser?.isLoggedIn !== true) {
        return;
      }

      SubtraceTagsBase.commonTags.user_id = currentUser.userId;

      const controller: AbortController = new AbortController();
      const { signal } = controller;

      const orgs: Api.ListOrgs_Item[] = await OrgUtils.listOrgs(signal);

      if (orgs.length === 0) {
        const response: Response = await ApiUtils.post<Api.OnboardNewUser_Request>(
          "/api/OnboardNewUser",
          { namespaceName: NamespaceUtils.getDefaultNamespaceName(currentUser.email) },
          { signal },
        );
        await ApiUtils.assertStatus(response, 200);
        const { namespace, org }: Api.OnboardNewUser_Response = await response.json();

        setOrg(StrictUtils.ensureDefined(org));
        setNamespaces([StrictUtils.ensureDefined(namespace)]);
        setCurrentNamespace(namespace);
        return;
      }

      setOrg(orgs[0]);
      const orgId: string = orgs[0].orgId;
      SubtraceTagsBase.commonTags.org_id = orgId;

      const namespaces: Api.ListNamespaces_Item[] = await NamespaceUtils.getNamespaces(orgId, signal);
      if (namespaces.length === 0) {
        throw new Error(`unexpected empty namespace list`);
      }
      setNamespaces(namespaces);

      for (let i = 0; i < namespaces.length; i++) {
        if (namespaces[i].name === getNamespaceFromUrl()) {
          setCurrentNamespace(namespaces[i]);
          return;
        }
      }

      for (let i = 0; i < namespaces.length; i++) {
        if (namespaces[i].namespaceId === localStorage.getItem("subtrace.lastNamespaceId")) {
          setCurrentNamespace(namespaces[i]);
          return;
        }
      }

      setCurrentNamespace(namespaces[0]);
    })();
  }, [currentUser]);

  React.useEffect(() => {
    if (currentNamespace) {
      localStorage.setItem("subtrace.lastNamespaceId", currentNamespace.namespaceId);
      SubtraceTagsBase.commonTags.namespace_id = currentNamespace.namespaceId;
    }
  }, [currentNamespace]);

  if (window.location.pathname === "/dashboard") {
    if (currentUser && !currentUser.isLoggedIn) {
      document.location = "/login";
      return null;
    }
  }

  return (
    <React.StrictMode>
      <AppErrorBoundary>
        <DialogContext.Provider value={dialogManager}>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="/pricing" element={<PricingPage />} />
              <Route path="/customers/*" element={<CustomersPage />} />
              <Route path="/link" element={<LinkPage />} />
              <Route path="/link/*" element={<LinkPage />} />
              <Route path="/dashboard" element={currentNamespace != null ? <Navigate to={{ pathname: `/dashboard/${currentNamespace.name}/live` }} /> : null} />
              <Route path="/dashboard/*" element={renderDashboardRoutes()} />
              <Route path="/login" element={<LoginPage />} />
            </Routes>
            {dialogState.isDialogOpen ? <Overlay className="absolute inset-0 w-dvw h-dvh flex items-center justify-center">{dialogState.dialogComponent}</Overlay> : null}
          </BrowserRouter>
        </DialogContext.Provider>
      </AppErrorBoundary>
    </React.StrictMode>
  );

  function renderDashboardRoutes(): React.ReactNode {
    if (!currentUser) {
      return null;
    }

    if (!currentUser.isLoggedIn) {
      return <Navigate to={{ pathname: "/login", search: `?next=${encodeURIComponent(window.location.pathname)}` }} />;
    }

    if (!namespaces || !currentNamespace) {
      return null;
    }

    const setCurrentNamespaceAndUrl: (namespace: Api.ListNamespaces_Item) => void = (namespace) => {
      setCurrentNamespace(namespace);
      SubtraceTagsBase.commonTags.namespace_id = namespace.namespaceId;

      const matches: RegExpExecArray | null = parseUrlRegex.exec(window.location.pathname);
      const currentNamespaceName: string | undefined = matches?.groups?.namespace;
      const remainingPath: string | undefined = matches?.groups?.rest;
      if (currentNamespaceName != null && remainingPath != null) {
        // TODO(sachin): Avoid a full page reload when navigating if possible
        // https://github.com/subtrace/monorepo/issues/289
        window.location.pathname = `/dashboard/${namespace.name}/${remainingPath}`;
      }
    };

    const namespaceManager: NamespaceManager = {
      currentNamespace,
      setCurrentNamespace: setCurrentNamespaceAndUrl,
      namespaces,
      setNamespaces,
    };

    return (
      <OrgContext.Provider value={org}>
        <NamespaceContext.Provider value={namespaceManager}>
          <div className="w-screen h-screen max-w-[100vw] max-h-screen flex">
            <Navigation />
            <div className="basis-2/3 grow shrink flex overflow-auto">
              <Routes>
                <Route path=":namespace_name">
                  <Route path="requests" element={<RequestsPage />} />
                  <Route path="live" element={<LivePage setupStatus={livePageSetupStatus} onSetupStatusChange={setLivePageSetupStatus} />} />
                  <Route path="tokens" element={<TokensPage />} />
                  <Route path="users" element={<UsersPage />} />
                </Route>
                <Route path="billing" element={<BillingPage />} />
              </Routes>
            </div>
          </div>
        </NamespaceContext.Provider>
      </OrgContext.Provider>
    );
  }
}

type DialogState =
  | {
      isDialogOpen: false;
    }
  | {
      isDialogOpen: true;
      dialogComponent: React.ReactNode;
    };
