import * as React from "react";
import { MessageSquareQuote } from "lucide-react";

import TrieveLogo from "../images/trieve_logo.png";
import { NavLinks } from "NavLinks";

const Trieve = () => {
  React.useEffect(() => {
    const orig = document.title;
    document.title = "Subtrace: Case Study | How Trieve resolves production issues in minutes with Subtrace";
    return () => {
      document.title = orig;
    };
  }, []);

  return (
    <div className="w-full h-vh flex flex-col px-4 lg:px-0">
      <div className="flex justify-center items-center">
        <NavLinks />
      </div>

      <div className="w-full flex justify-center">
        <div className="w-full max-w-5xl py-8 px-4 md:px-8">
          <div className="w-full max-w-2xl text-[16px] md:text-[18px] text-zinc-400/75 flex flex-col space-y-5 leading-[26px] md:leading-[31px] md:pt-8">
            <span className="text-zinc-700 select-none font-medium text-[11px] uppercase">Case Study</span>

            <h1 className="!mt-4 text-[30px] md:text-[42px] text-zinc-100 font-semibold leading-[47px] md:leading-[64px]">
              How Trieve resolves production issues in minutes with Subtrace
            </h1>

            <span />

            <div className="flex flex-col space-y-8 relative">
              <div className="w-full flex justify-center rounded outline outline-[1px] outline-zinc-900/90 contrast-[0.85] bg-black px-4 py-12 md:py-24">
                <img src={TrieveLogo} className="w-full rounded grayscale brightness-[1.50] max-w-[12rem] md:max-w-[16rem]" />
              </div>

              <div className="flex flex-col space-y-4 lg:absolute lg:right-0 lg:translate-x-[calc(100%+32px)] lg:max-w-[20rem]">
                <div className="flex flex-col space-y-1 text-xs w-full">
                  <span className="text-zinc-400 font-medium">Company</span>
                  <span className="text-zinc-400/80">Trieve</span>
                </div>
                <div className="flex flex-col space-y-1 text-xs w-full">
                  <span className="text-zinc-400 font-medium">Website</span>
                  <a target="_blank" href="https://trieve.ai" className="text-zinc-400/80 hover:text-zinc-400">
                    https://trieve.ai
                  </a>
                </div>
                <div className="flex flex-col space-y-1 text-xs w-full">
                  <span className="text-zinc-400 font-medium">Description</span>
                  <span className="text-zinc-500">
                    Infrastructure for search teams building retrieval and RAG. Trieve combines search language models with tools for tuning ranking and relevance.
                  </span>
                </div>
                <div className="flex flex-col space-y-1 text-xs w-full">
                  <span className="text-zinc-400 font-medium">Industry</span>
                  <span className="text-zinc-500">Technology</span>
                </div>
              </div>
            </div>

            <span />

            <p>
              Trieve's mission is to make AI search accurate, reliable, and fast. Founded in 2023, Trieve lets developers add a conversational search bar to their app in just
              minutes. Over 30,000 websites use Trieve to handle tens of millions of queries every month.
            </p>

            <p>
              Engineers at Trieve use Subtrace every day to debug their backend services and resolve incidents quickly. After struggling to root cause issues with traditional
              observability platforms, they switched to Subtrace and improved their resolution time on revenue-critical incidents from months to just minutes. Trieve’s founders
              tell us how important Subtrace has been for them.
            </p>

            <div className="!mt-12 -mx-8 md:mx-0 px-8 py-6 md:px-12 md:py-10 bg-zinc-900/80 outline outline-[1px] outline-zinc-800/80 md:rounded flex flex-col space-y-4">
              <div className="flex items-center space-x-2">
                <MessageSquareQuote size={16} className="text-zinc-700" />
                <span className="select-none uppercase text-zinc-700 text-[10px] font-semibold leading-[10px]">Top Highlight</span>
              </div>
              <p className="text-zinc-400 text-[18px] leading-[32px] md:text-[20px] md:leading-[32px] font-medium">
                “It's like living in utopia. I never want to build anything without Subtrace ever again. Life changing piece of software.”
              </p>
              <div className="flex flex-col">
                <div className="font-medium text-zinc-400 text-sm">Nick Khami</div>
                <div className="text-zinc-500/80 text-sm">
                  <span className="opacity-[1.00]">CEO, Trieve</span> <span className="opacity-[0.50]">(YC W24)</span>
                </div>
              </div>
            </div>

            <p className="!mt-12 mb-8 text-[24px] text-zinc-300 font-semibold">Death by a thousand tiny outages</p>

            <p>
              Searching millions of documents in milliseconds at scale is incredibly difficult. Before Subtrace, Trieve constantly struggled with outages and latency spikes in
              their core services. They tried a patchwork of observability tools to fix these problems, but every incident became a nightmare spanning five monitoring tools, twelve
              browser tabs, and zero answers to the questions that matter.
            </p>

            <p>
              “Our first set of questions are the same every time we debug an API error or slow request: which tenant, which API route, what was the query payload, and what was the
              response,” says Nick Khami, Trieve's CEO. “Answering any one of those questions was an ordeal. It was incredibly difficult to see the actual details of any given
              request.”
            </p>

            <p>
              After months of alerts that always howled <i>something's wrong</i> and never <i>here's why</i>, they decided it was time to look for alternatives.
            </p>

            <p className="!mt-12 mb-8 text-[24px] text-zinc-300 font-semibold">Finding the signal in the noise</p>

            <p>When Denzell Ford, Trieve's CTO, opened Subtrace for the first time, he expected yet another dashboard. What he got was a flashlight.</p>

            <p>
              Setup was just one command—no dependencies, no code changes, no complex permissions. Trieve's engineers could suddenly see every API request flowing through their
              systems in real time. “We instantly identified the root cause of a major latency spike to be a bad Postgres query,” says Denzell. “Using Subtrace, we also discovered
              errors in our service that we couldn't see anywhere else before.”
            </p>

            <p>
              With Subtrace, they could replay exact requests, see headers, analyze latency breakdowns. No more guessing. Things they'd previously dismissed as random glitches
              suddenly had names, traces, timelines. Engineers could now fix the root cause, not the symptom.
            </p>

            <p className="!mt-12 mb-8 text-[24px] text-zinc-300 font-semibold">
              Trieve's conclusion: Subtrace makes them more reliable and lets them respond to customer issues faster
            </p>

            <p>
              Recently, a customer reported an API outage via Slack. Before Subtrace, Trieve's engineers would've wasted hours sifting through disconnected logs across dozens of
              servers. This time, they used Subtrace to simply find the customer's exact request based on a HTTP header, then inspected its response status code and error message
              in the payload. It turned out that Kubernetes had failed to automatically restart an unhealthy pod. They manually restarted it, then used Subtrace again to verify
              that the fix actually worked for the customer by checking the status code of subsequent requests. It worked. The issue wasn't complex—just invisible. The whole
              incident was resolved faster than the customer's next Slack message.
            </p>

            <p>
              This is just one example of how Trieve uses Subtrace to improve reliability and respond to customer incidents faster. “We now have the utmost confidence in
              communicating through issues with our customers,” says Nick.
            </p>

            <p>
              Nick and Denzell also appreciate Subtrace's open source development. “Trieve is open source, so it makes sense that our tools would be too,” says Nick. Above all,
              they love Subtrace because it lets them identify the root cause of each incident in just minutes, leading to fewer outages, smoother operations, and happier
              customers.
            </p>

            <p>“It's like living in utopia,” Nick concluded. “I never want to build anything without Subtrace ever again. Life changing piece of software.”</p>
          </div>
        </div>
      </div>

      <div className="mt-6 md:mt-12 flex justify-center">
        <div className="w-full max-w-5xl py-8 px-4 md:px-8 flex space-x-4">
          <div className="aspect-[2/1] w-full max-w-lg flex flex-col justify-center items-center p-16 rounded-lg outline outline-[1px] outline-zinc-800/80 flex flex-col space-y-6 md:space-y-8 overflow-hidden relative">
            <div className="text-lg md:text-xl font-semibold flex items-center text-zinc-300">Try Subtrace for free</div>
            <a
              href="/login"
              className="max-w-fit whitespace-pre group/button px-6 py-2 flex justify-center items-center space-x-2 rounded w-full text-sm font-medium bg-zinc-100 hover:brightness-[0.90] cursor-pointer text-zinc-700"
            >
              <div>Get Started</div>
              <div className="duration-[100ms] translate-x-[-1px] group-hover/button:translate-x-[+1px]">&rarr;</div>
            </a>
            <div
              className="z-[-5] opacity-[0.6] absolute top-[-100%] left-[-100%] w-[300%] h-[300%] animate-[bg-move_3s_linear_infinite]"
              style={{
                background: "radial-gradient(#27272a 25%, transparent 25%), radial-gradient(#27272a 25%, transparent 25%)",
                backgroundPosition: "0px 0px, 96px 96px",
                backgroundSize: "192px 192px",
                backgroundColor: "#18181b",
              }}
            />
          </div>
        </div>
      </div>

      <div className="mt-24 flex justify-center items-center">
        <NavLinks />
      </div>
    </div>
  );
};

export function CustomersPage() {
  return <Trieve />;
}
