import * as React from "react";
import { PlayIcon, PauseIcon, StepForwardIcon } from "lucide-react";

import * as ArrayUtils from "Utils/ArrayUtils";
import * as StyleUtils from "Utils/StyleUtils";
import AWSLogo from "../images/aws_logo.svg";
import AzureLogo from "../images/azure_logo.svg";
import TrieveLogo from "../images/trieve_logo.png";
import DockerLogo from "../images/docker_logo.svg";
import GCPLogo from "../images/gcp_logo.svg";
import GoLogo from "../images/go_logo.svg";
import JavaLogo from "../images/java_logo.svg";
import KubernetesLogo from "../images/kubenetes_logo.svg";
import NickProfilePicture from "../images/NickProfilePicture.jpg";
import NodejsLogo from "../images/nodejs_logo.svg";
import PhpLogo from "../images/php_logo.svg";
import RubyLogo from "../images/ruby_logo.svg";
import RustLogo from "../images/rust_logo.svg";
import PythonLogo from "../images/python_logo.svg";
import YCLogo from "../images/yc_logo.svg";
import XLogo from "../images/x_logo.svg";
import { NavLinks } from "NavLinks";

function Orbit(props: { children: React.ReactElement[]; radius: number; numberOfItems: number }): React.ReactElement | null {
  const size = props.children.length ?? 1;
  const { numberOfItems } = props;

  return (
    <div className="pointer-events-none absolute top-0 left-0 w-full h-full">
      <div
        className={StyleUtils.mergeClassNames(
          "pointer-events-auto relative rounded-full border border-dashed border-zinc-700/40",
          props.radius > 300 ? "animate-spin-slow-2" : "animate-spin-slow-1",
          props.radius > 300 ? "rotate-90" : "",
        )}
        style={{
          top: `calc(120% - ${props.radius}px)`,
          left: `calc(50% - ${props.radius}px)`,
          width: `${2 * props.radius}px`,
          height: `${2 * props.radius}px`,
        }}
      >
        {ArrayUtils.extendByRepetition(props.children, numberOfItems).map((_element, i) => (
          <div
            key={i}
            className="absolute"
            style={{
              transform: `rotate(${90 - (i * 360) / numberOfItems}deg)`,
              left: `calc(50% - 16px + ${props.radius * Math.cos((i * 2 * Math.PI) / numberOfItems)}px)`,
              top: `calc(50% - 16px - ${props.radius * Math.sin((i * 2 * Math.PI) / numberOfItems)}px)`,
            }}
          >
            {props.children[i % size]}
          </div>
        ))}
      </div>
    </div>
  );
}

function Integrations(): React.ReactElement {
  function Icon(props: { name: string; src: string; href?: string }): React.ReactElement {
    const ret = (
      <div className="w-7 h-7 group relative">
        <div className="absolute w-full h-full flex justify-center items-center">
          <img
            className="absolute w-full transition ease-in-out opacity-[0.75] group-hover:opacity-[1.00] grayscale group-hover:grayscale-0 contrast-[0.25] group-hover:contrast-[1.00] brightness-[1.00] group-hover:brightness-[1.00]"
            src={props.src}
            alt={props.name}
          />
        </div>
        <span className="whitespace-pre hidden group-hover:block select-none absolute left-[50%] translate-x-[-50%] -top-7 text-center text-[10px] font-medium leading-[10px] bg-zinc-800 text-zinc-400 px-2 py-1 rounded-sm border border-zinc-700">
          {props.name}
        </span>
      </div>
    );

    if (props.href) {
      return (
        <a target="_blank" href={props.href}>
          {ret}
        </a>
      );
    }
    return ret;
  }

  return (
    <div className="overflow-hidden absolute left-0 top-0 w-full h-full">
      <div className="relative top-0 left-0 w-full h-full">
        <div
          className="absolute top-[calc(100%-8rem)] left-[calc(50%-12rem)] w-[24rem] h-[16rem] blur-[64px]"
          style={{
            background: "radial-gradient(#fcd34d40 0%, #fcd34d20 25%, transparent 50%, transparent 100%)",
          }}
        />
        <Orbit radius={360} numberOfItems={16}>
          <Icon name="Node.js" src={NodejsLogo} href="https://docs.subtrace.dev/nodejs-express" />
          <Icon name="Python" src={PythonLogo} href="https://docs.subtrace.dev/python-fastapi" />
          <Icon name="Go" src={GoLogo} href="https://docs.subtrace.dev/golang" />
          <Icon name="Java" src={JavaLogo} />
          <Icon name="PHP" src={PhpLogo} href="https://docs.subtrace.dev/php-laravel" />
          <Icon name="Rust" src={RustLogo} />
          <Icon name="Ruby" src={RubyLogo} />
        </Orbit>
        <Orbit radius={240} numberOfItems={12}>
          <Icon name="Docker" src={DockerLogo} href="https://docs.subtrace.dev/docker" />
          <Icon name="Kubernetes" src={KubernetesLogo} href="https://docs.subtrace.dev/kubernetes" />
          <Icon name="AWS" src={AWSLogo} />
          <Icon name="Azure" src={AzureLogo} />
          <Icon name="Google Cloud" src={GCPLogo} />
        </Orbit>
      </div>
    </div>
  );
}

function DemoVideos() {
  const videoId = React.useId();
  const [selected, setSelected] = React.useState("Search");
  const [isPlaying, setPlaying] = React.useState(false);

  let url: string;
  switch (selected) {
    case "Search":
      url = "https://subtrace-static.pages.dev/search.mp4";
      break;
    case "Inspect":
      url = "https://subtrace-static.pages.dev/inspect.mp4";
      break;
    case "Replay":
      url = "https://subtrace-static.pages.dev/replay.mp4";
      break;
    case "Collaborate":
      url = "https://subtrace-static.pages.dev/collaborate.mp4";
      break;
    default:
      throw new Error(`unexpected selected: ${selected}`);
  }

  const select = (text: string) => {
    setSelected(text);
    setPlaying(true);
  };

  const Button = (id: number, text: string) => {
    return (
      <button
        onClick={() => select(text)}
        className={[
          "text-xs font-medium px-2 md:px-4 py-2 flex items-center space-x-2",
          text === selected ? "text-zinc-300 hover:text-zinc-300" : "text-zinc-400/80 hover:text-zinc-300/80",
        ].join(" ")}
      >
        <span
          className={["text-[10px] bg-zinc-800/40 rounded-full w-6 h-6 hidden md:flex items-center justify-center", text === selected ? "text-zinc-300" : "text-zinc-500/80"].join(
            " ",
          )}
        >
          {text === selected ? <StepForwardIcon size={10} /> : <span>{id}</span>}
        </span>
        <span>{text}</span>
      </button>
    );
  };

  React.useEffect(() => {
    const el = document.getElementById(videoId) as HTMLVideoElement;
    if (isPlaying) {
      try {
        el.play();
      } catch {}
    } else {
      try {
        el.pause();
      } catch {}
    }
  });

  const toggle = () => {
    const el = document.getElementById(videoId) as HTMLVideoElement;
    if (el.paused) {
      setPlaying(true);
    } else {
      setPlaying(false);
    }
  };

  return (
    <div className="w-full flex flex-col space-y-2 items-center justify-center">
      <div className="rounded-xl w-full bg-zinc-800/20 p-2">
        <div className="rounded-lg w-full bg-zinc-800/20 flex justify-center items-center space-x-2 md:space-x-4">
          {Button(1, "Search")}
          {Button(2, "Inspect")}
          {Button(3, "Replay")}
          {Button(4, "Collaborate")}
        </div>
      </div>
      <div className="rounded-xl w-full bg-zinc-800/30 p-2 overflow-hidden relative group flex">
        <div className="aspect-[1920/1080] rounded-lg flex justify-center items-center w-full h-full overflow-hidden">
          <video loop id={videoId} src={url} controls={false} width="100%" height="100%" className="w-full h-full">
            <span className="text-zinc-200 mx-auto my-auto">{selected}</span>
          </video>
        </div>
        <button
          onClick={() => toggle()}
          className={[
            "absolute top-0 left-0 w-full h-full flex justify-center items-center text-xs font-medium transition-all duration-[100ms]",
            isPlaying ? "bg-transparent" : "bg-black/30",
          ].join(" ")}
        >
          {isPlaying ? (
            <div className="w-32 h-32 hidden md:group-hover:flex flex-col space-y-4 justify-center items-center rounded-xl bg-zinc-900/70 backdrop-brightness-125 backdrop-blur-xl text-zinc-100 p-4">
              <PauseIcon size={32} />
              <span>Pause</span>
            </div>
          ) : (
            <div className="w-32 h-32 brightness-[0.90] group-hover:brightness-[1.00] space-y-4 flex flex-col justify-center items-center rounded-xl bg-zinc-900/70 backdrop-brightness-125 backdrop-blur-xl text-zinc-100 p-4">
              <PlayIcon size={32} />
              <span>Show Example</span>
            </div>
          )}
        </button>
      </div>
    </div>
  );
}

function CodeSnippet() {
  const code: Record<string, string> = {
    Python: "python main.py",
    Node: "node server.js",
    Go: "go run .",
    FastAPI: "fastapi run main.py",
    Flask: "flask --app hello run",
    Gunicorn: "gunicorn -w 4 myapp:app",
    Laravel: "php artisan serve",
  };

  const [selectedLanguage, setSelectedLanguage] = React.useState("Python");

  return (
    <div className="w-full bg-zinc-900 px-6 pt-[10px] pb-[20px] flex flex-col space-y-[6px]">
      <div className="flex mx-[-6px] items-center">
        {Object.entries(code).map(([language]) => (
          <button
            key={language}
            onClick={() => setSelectedLanguage(language)}
            className={[
              "cursor-pointer font-medium px-[6px] py-[2px] text-[10px]",
              language === selectedLanguage ? "text-zinc-400/80 hover:text-zinc-400" : "text-zinc-600/70 hover:text-zinc-600",
            ].join(" ")}
          >
            {language}
          </button>
        ))}
      </div>
      <div className="md:min-w-[320px] flex space-x-[10px] text-[12px] items-center">
        <span className="text-zinc-500/70 select-none">$</span>
        <span className="font-mono font-medium text-zinc-300/80">subtrace run -- {code[selectedLanguage]}</span>
      </div>
    </div>
  );
}

export function LandingPage(): React.ReactElement {
  return (
    <div className="w-full flex flex-col px-4 lg:px-0 relative">
      <div className="z-[-100] absolute top-0 left-0 w-full h-full hidden xl:flex justify-center items-center">
        <div className="w-full h-full max-w-6xl bg-zinc-950 border-l border-r border-zinc-900/90"></div>
      </div>

      <div className="mt-0 md:mt-4 flex justify-center items-center">
        <NavLinks />
      </div>

      <div className="mt-12 md:mt-16 flex justify-center">
        <h2 className="font-semibold whitespace-pre text-4xl sm:text-4xl md:text-5xl space-y-1 lg:space-y-2 w-full text-center lg:max-w-xl flex flex-col items-center px-8 lg:px-4 text-zinc-100 !leading-[1.20]">
          <span>Wireshark for</span>
          <span>Docker containers</span>
        </h2>
      </div>

      <div className="mt-6 flex justify-center">
        <div className="text-zinc-500 text-[15px] max-w-[20rem] text-center">See all incoming and outgoing requests in Docker containers with just one command</div>
      </div>

      <div className="mt-10 md:mt-12 flex items-center flex-col space-y-4">
        <div className="rounded border border-zinc-800/80 overflow-hidden divide divide-y divide-zinc-300/50">
          <CodeSnippet />
          <a href="/login" className="hover:brightness-[0.93] bg-zinc-100 group px-8 py-5 w-full max-w-[24rem] text-[16px] text-center flex flex-col space-y-[3px]">
            <div className="text-md text-zinc-800 font-medium">
              <span>Start debugging</span>
              <span className="inline-block ml-2 transition ease-in-out translate-x-[-1px] group-hover:translate-x-[+1px]">&rarr;</span>
            </div>
            <div className="text-zinc-400/70 text-[10px]">No credit card required. Free forever.</div>
          </a>
        </div>
      </div>

      <div className="mt-12 flex justify-center">
        <div className="w-fit group">
          <a href="https://www.ycombinator.com/companies/subtrace" target="_blank" className="flex justify-center space-x-3 px-2 -mx-2 py-1 -my-1">
            <img src={YCLogo} className="w-4 h-4" alt="Backed by Y Combinator" />
            <span className="text-[11px] text-zinc-500 group-hover:text-zinc-300 transition-all ease-in-out duration-300 font-medium">Backed by Y Combinator</span>
          </a>
        </div>
      </div>

      <div className="mt-16 md:mt-24 flex justify-center md:px-4">
        <div className="w-full max-w-4xl flex justify-center items-center">
          <DemoVideos />
        </div>
      </div>

      <div className="mt-48 flex justify-center">
        <div className="w-full max-w-[32rem] flex flex-col items-center rounded-lg overflow-hidden outline outline-zinc-800/80 outline-[1px] divide divide-y divide-zinc-800/80">
          <a
            className="bg-zinc-900/85 hover:bg-zinc-900/70 px-8 py-6 flex flex-col space-y-6"
            href="https://x.com/skeptrune/status/1879072724796244194"
            target="_blank"
            rel="noreferrer"
          >
            <div className="text-zinc-300 text-sm flex flex-col space-y-2">
              <p>
                I never want to build anything without
                <span> </span>
                <a target="_blank" className="hover:brightness-[1.20]" href="https://x.com/subtrace_dev" rel="noreferrer">
                  @subtrace_dev
                </a>
                <span> </span>
                configured locally ever again. Life changing piece of software.
              </p>
            </div>
            <div className="flex justify-between items-center">
              <div className="flex justify-center item-center space-x-2 text-xs">
                <img className="mx-[2px] w-4 h-4 rounded-full" src={NickProfilePicture} />
                <a href="https://x.com/skeptrune" target="_blank" className="text-zinc-400/80 hover:brightness-[1.2] font-semibold" rel="noreferrer">
                  Nick Khami
                </a>
                <span className="text-zinc-500/80 font-medium">CEO, Trieve (YC W24)</span>
              </div>
              <img className="h-3 w-3 invert opacity-[0.50]" src={XLogo} />
            </div>
          </a>
          <div className="w-full relative group px-8 py-6 flex justify-between items-center space-x-3 md:space-x-6 bg-black">
            <img src={TrieveLogo} className="w-[4rem] md:w-[5rem] grayscale opacity-[0.15] brightness-[3.00] ml-auto" />
            <a
              href="/customers/trieve"
              className="min-w-fit max-w-fit px-4 py-2 duration-[100ms] group/button flex justify-center items-center space-x-2 rounded w-full text-xs font-medium bg-zinc-900/85 outline outline-[1px] outline-zinc-800 hover:brightness-[0.90] cursor-pointer text-zinc-200"
            >
              <div>Read full case study</div>
              <div className="duration-[100ms] translate-x-[-1px] group-hover/button:translate-x-[+1px]">&rarr;</div>
            </a>
          </div>
        </div>
      </div>

      <div className="mt-48 flex justify-center">
        <div className="flex flex-col space-y-[6px] md:space-y-4 max-w-[100%]">
          <div className="w-full flex items-center text-[14px] md:text-[16px] font-medium">
            <div className="relative overflow-hidden w-full h-96 px-6 py-4 bg-zinc-900/70 rounded-xl">
              <span className="text-zinc-300">Supports every language.</span>
              <span> </span>
              <span className="text-zinc-500">Finally, automatic tracing that just works. For everyone.</span>
              <Integrations />
            </div>
          </div>

          <div className="-mx-[6px] md:-mx-2 grid grid-cols-2 md:grid-cols-4 text-[13px] md:text-[14px] justify-items-center">
            <div className="mx-[6px] md:mx-2 my-[6px] md:my-0 max-w-48 aspect-square overflow-hidden p-4 md:p-5 bg-zinc-900/80 rounded-xl">
              <span className="font-medium text-zinc-200">Predictable pricing.</span>
              <span> </span>
              <span className="font-normal text-zinc-500">Flat and simple pricing that anyone can understand. No surprises.</span>
            </div>
            <div className="mx-[6px] md:mx-2 my-[6px] md:my-0 max-w-48 aspect-square overflow-hidden p-4 md:p-5 bg-zinc-900/80 rounded-xl">
              <span className="font-medium text-zinc-200">Built on ClickHouse.</span>
              <span> </span>
              <span className="font-normal text-zinc-500">Search across millions of requests in just milliseconds using the fastest database ever.</span>
            </div>
            <div className="mx-[6px] md:mx-2 my-[6px] md:my-0 max-w-48 aspect-square overflow-hidden p-4 md:p-5 bg-zinc-900/80 rounded-xl">
              <span className="font-medium text-zinc-200">No code changes.</span>
              <span> </span>
              <span className="font-normal text-zinc-500">Keep your code clean with fully automatic instrumentation.</span>
            </div>
            <div className="mx-[6px] md:mx-2 my-[6px] md:my-0 max-w-48 aspect-square overflow-hidden p-4 md:p-5 bg-zinc-900/80 rounded-xl">
              <span className="font-medium text-zinc-200">Always open source.</span>
              <span> </span>
              <span className="text-zinc-500">Avoid vendor lock-in.</span>
              <span> </span>
              <a target="_blank" href="https://github.com/subtrace/subtrace" className="text-zinc-500 hover:text-zinc-300">
                Star the GitHub repo
              </a>
              <span> </span>
              <span className="text-zinc-500">to support us.</span>
            </div>
          </div>

          <div className="flex px-3 md:px-5 py-[4px] md:py-2 bg-zinc-900/80 rounded-xl">
            <div className="w-full flex flex-col space-y-1 md:flex-row md:space-y-0 items-center md:justify-between py-2 md:py-0">
              <div className="text-[17px] md:text-[14px] font-medium py-4 md:py-0 flex flex-col md:flex-row space-x-[2px] items-center text-zinc-100">
                <span className="text-zinc-200">Want to debug faster?</span>
                <span> </span>
                <span className="text-zinc-500 hidden md:block">Start using Subtrace.</span>
              </div>
              <a
                href="/login"
                className="rounded-lg md:mb-0 bg-zinc-800/35 md:bg-zinc-800/50 hover:brightness-[0.90] group md:-mx-3 px-8 py-4 md:py-[10px] w-full md:w-[calc(25%-16px)] text-[15px] md:text-[14px] text-center flex flex-col space-y-[4px]"
              >
                <div className="text-zinc-200 font-medium flex justify-center flex-nowrap">
                  <span className="text-nowrap">Start for free</span>
                  <span className="inline-block ml-2 transition ease-in-out translate-x-[-1px] group-hover:translate-x-[+1px]">&rarr;</span>
                </div>
                <div className="text-zinc-600/60 text-[10px] font-medium md:hidden">No credit card required</div>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-48 mb-16 flex justify-center items-center">
        <NavLinks />
      </div>
    </div>
  );
}
